import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import InviteUserModal from "../../../../components/Modals/InviteUsers.modal";
import {
    getCompanyDetails,
    getUsersForSettings,
    getRoles,
    resetUserPassword,
    deleteUser,
    deleteUserByUuid,
    markUserActiveByUuid,
    markUserInactiveByUuid,
    updateSmsNotification,
    getAddedUsers,
    updateNewLeadNotification,
    updateSiteVisitNotification
} from "../../../../services/private/company.service";
import { createDatewithTime } from "../../../../helpers/date"
import PopUpModal from '../../../../components/Modals/PopUpModal';
import InputSelect from "../../../../components/InputGroup/InputSelect";
import { useHistory } from "react-router";
import UserLimitExeedModal from "../../../../components/Modals/userLimitExeedModal";
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import Consumer from '../../../../helpers/context';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as SearchCloseIcon } from '../../../../assets/icons/searchClose.svg';
import { isNumber } from "../../../../helpers/helpers";
import UserFilters from "./UserFilters";

const USER_UUID = localStorage.getItem('user');

const getClassName = (exist) => {
    switch (exist) {
        case 'active':
            return 'hot-btn'
        case 'inactive':
            return 'dead-btn'
    }
}

const filtersEnum = {
    roles: 'Role',
    status: 'Status'
}

export default function SettingsUser() {
    const [showUserModal, setShowUserModal] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [userDetails, setUserDetails] = useState();
    const [allUserDetails, setAllUserDetails] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState({
        value: 10,
        label: "10/Page"
    });
    const [companyDetails, setCompanyDetails] = useState({});
    const [selectedUser, setSelectedUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [users, setusers] = useState();
    const [reAssignUser, setReAssignUser] = useState();
    const [error, setError] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState([]);
    const [showFilters, setShowFilters] = useState(false);

    let { allowedPermissions, isRolesModule } = useContext(Consumer);

    const ROLE_NAME = localStorage.getItem('role');
    const history = useHistory();

    const getUserData = async () => {
        const { roles, status } = filters;
        let role = JSON.stringify(roles)
        const filterStatus = status?.length ? status[0] : null
        await getUsersForSettings('', role, filterStatus).then(res => {
            if (res.status === 200) {
                setUserDetails(res.data.data);
                setAllUserDetails(res.data.data);
                const users = res.data.data
                    .filter(user => user.is_active)
                    .map(user => { return { ...user, label: `${user.name} (${user.role_name})`, value: user.uuid } })
                setusers(users)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getCompanyInfo = async () => {
        await getCompanyDetails().then(res => {
            if (res.status === 200) {
                setCompanyDetails(res.data.data)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getAllRoles = () => {
        getRoles().then(res => {
            if (res.status === 200) {
                const createRoleOptions = res.data.data.filter(item => item.name !== 'Super Admin').map((data) => {
                    const { name, uuid } = data;
                    return {
                        ...data,
                        label: name,
                        value: uuid
                    }
                })
                setRoles(createRoleOptions)
            }
        })
    }

    const resetPassword = async (uuid) => {
        await resetUserPassword(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }


    const deleteUserAction = async () => {
        await deleteUserByUuid(selectedUser.uuid, reAssignUser.value).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUserData();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleSearch = () => {
        let searchedData = []
        if (isNumber(searchText)) {
            searchedData = allUserDetails?.filter(item => item.mobile.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        } else {
            searchedData = allUserDetails?.filter(item => item.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
        }
        setUserDetails(searchedData)
    }

    useEffect(() => {
        if (searchText)
            handleSearch();
    }, [searchText]);

    useEffect(() => {
        getCompanyInfo();
        getUserData();
        getAllRoles();

    }, [])

    useEffect(() => {
        if (filters) {
            getUserData();
        }
    }, [filters])
    // useEffect(() => {
    //     if (ROLE_NAME !== 'ADMIN') {
    //         history.push('/404')
    //     }
    // }, [ROLE_NAME])

    const handleDeleteUser = async () => {
        if (!reAssignUser) {
            setError(true);
            return;
        } else {
            setError(false)
        }
        await deleteUserAction();
        setReAssignUser('')
        setShowDeleteUser(false)
    }

    const handleActive = (uuid) => {
        markUserActiveByUuid(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUserData();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleInactive = (uuid) => {
        markUserInactiveByUuid(uuid).then(res => {
            if (res.status === 200) {
                toast.success(res.data.message)
                getUserData();
            }
        }).catch((e) => {
            toast.error(e.response.data.message);
        });
    }

    const handleUpdateSMSNotification = (uuid, value) => {
        updateSmsNotification(uuid, { sms_notification: value }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getUserData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const handleUpdateNewLeadNotification = (uuid, value) => {
        updateNewLeadNotification(uuid, { value: value }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getUserData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const handleUpdateSVLeadNotification = (uuid, value) => {
        updateSiteVisitNotification(uuid, { value: value }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getUserData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const renderFilterList = (filterLists) => {
        console.log(filtersEnum[filterLists], 'filtersEnum[filterLists]')
        if (filtersEnum[filterLists] === 'Role') {
            return (
                roles.map((item, index) => {
                    return filters[filterLists].map((role) => {
                        if (role == item.role_id) {
                            return <li key={`${item.name}_${index}`}>{item.name}</li>
                        }
                    })
                })
            )
        }
        if (filtersEnum[filterLists] === 'Status') {
            return <li>Active</li>
        }

    }

    const removeFilterValue = (keyValue) => {
        let oldFilters = structuredClone(filters);
        delete oldFilters[keyValue]
        setFilters({ ...oldFilters });
    }

    console.log(filters, 'filterss')
    return (
        <>
            {showUserModal ?
                <InviteUserModal
                    show={showUserModal}
                    setShow={setShowUserModal}
                    selectedUser={selectedUser}
                    getUserData={getUserData}
                    roles={roles}
                />
                : null}
            {showDeleteUser ?
                <PopUpModal
                    setShow={setShowDeleteUser}
                    show={showDeleteUser}
                    heading={<>
                        Delete {`${selectedUser?.name}`} ?
                    </>}
                    body={<div className="p-3">
                        <div className="mb-4">
                            <InputSelect
                                index={4}
                                name="user"
                                value={reAssignUser}
                                options={users?.filter(user => user.value !== selectedUser?.uuid)}
                                onChange={(e) => setReAssignUser(e)}
                                placeholder="Select User"
                                label={`Select user to reassign ${selectedUser?.name} Leads`}
                                error={error ? 'Please select user' : ''}
                            />
                        </div>
                        {/* <div className="mb-4">
                             {`Deleting ${selectedUser?.name} will re-assign all ${selectedUser?.name} leads to admin?`}
                            </div> */}
                        <div className="justify-content-end d-flex">
                            <button className="fw-po-medium mr-16 outline-btn px-24" onClick={() => setShowDeleteUser(false)}>Cancel</button>
                            <button className="fw-po-medium del-btn px-16" onClick={handleDeleteUser}>Confirm Delete</button>

                        </div>
                    </div>}
                /> : null}
            <div className="users w-100 h-full flex flex-col">
                <div className="settings-title">
                    <div className="row d-flex d-flex flex-md-fill flex-wrap">
                        <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                            <h1 className="fz24 mb-16 black fw-po-medium">Users</h1>
                            <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all users for {companyDetails.name}</p>
                        </div>
                        <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                            <div>
                                <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                    <SearchIcon className='mr-2' />
                                    <input
                                        type="text"
                                        placeholder='Search For Users'
                                        className=' w-100 focus:outline-none text-sm'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {searchText &&
                                        <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                            <SearchCloseIcon />
                                        </div>}
                                </div>
                            </div>
                            <div className="">
                                {(!isRolesModule || allowedPermissions?.includes('add_new_user')) && <button onClick={() => { setSelectedUser(null); setShowUserModal(true) }} className="pr-btn flex">
                                    <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <mask id="path-1-inside-1_1117_451" fill="white">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                        </mask>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                        <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                    </svg>
                                    Invite User</button>}
                            </div>
                            <div className="filter-btn !ml-4">
                                <button className={`d-flex align-items-center ${Object.keys(filters)?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setShowFilters(true)}>
                                    <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                    <span className="text-capitalize">Filter {Object.keys(filters)?.length > 0 ? ` (${Object.keys(filters)?.length})` : " "}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-table ml-24 mt-24">
                    {Object.keys(filters).length > 0 ? <div className="all-leads !h-auto filter-applyed d-flex align-items-center pb-1">
                        <span className="">Filtered by: &nbsp;</span>
                        <ul className="tags align-items-center pl-1 !pt-1" id="scroll-tag">
                            {Object.keys(filters).map((data, index) => {
                                return (
                                    <div className="item" key={String(index)}>
                                        <li >
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id="button-tooltip" className='sidebar-tooltip tags-tooltip' >
                                                        <div className='info-msg'>
                                                            <ul className="mb-0">
                                                                {renderFilterList(data)}
                                                                {/* <li>1. Signed up by mistake</li> */}
                                                                {/* <li>2. Signed up by mistake</li> */}
                                                            </ul>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <Button onClick={() => removeFilterValue(data)} className='bg-tr border-0 p-0' bsstyle="default" style={{ fontSize: 0 }}>
                                                    <svg className="ml-0 mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M7.02344 5.07518C7.02344 5.33376 7.12616 5.58176 7.30901 5.76461C7.49186 5.94746 7.73985 6.05018 7.99844 6.05018C8.25702 6.05018 8.50502 5.94746 8.68787 5.76461C8.87071 5.58176 8.97344 5.33376 8.97344 5.07518C8.97344 4.81659 8.87071 4.5686 8.68787 4.38575C8.50502 4.2029 8.25702 4.10018 7.99844 4.10018C7.73985 4.10018 7.49186 4.2029 7.30901 4.38575C7.12616 4.5686 7.02344 4.81659 7.02344 5.07518ZM7.35884 11.3672C7.38597 11.517 7.46487 11.6526 7.58177 11.7503C7.69867 11.8479 7.84613 11.9014 7.99844 11.9014C8.15074 11.9014 8.29821 11.8479 8.41511 11.7503C8.532 11.6526 8.6109 11.517 8.63804 11.3672L8.64844 11.2502V8.00018L8.63804 7.88318C8.6109 7.73331 8.532 7.59773 8.41511 7.5001C8.29821 7.40247 8.15074 7.34899 7.99844 7.34899C7.84613 7.34899 7.69867 7.40247 7.58177 7.5001C7.46487 7.59773 7.38597 7.73331 7.35884 7.88318L7.34844 8.00018V11.2502L7.35884 11.3672Z" fill="#92929D" />
                                                        <rect x="0.75" y="-0.75" width="11.5" height="11.5" rx="5.75" transform="matrix(1 0 0 -1 1.5 13)" stroke="#92929D" strokeWidth="1.5" />
                                                    </svg>

                                                </Button>
                                            </OverlayTrigger>
                                            {filtersEnum[data]}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M8.94099 7.99988L12.459 11.5211C12.7193 11.7816 12.7191 12.2037 12.4586 12.4639C12.1981 12.7242 11.776 12.724 11.5158 12.4635L7.99748 8.94199L4.47097 12.4633C4.21043 12.7234 3.78832 12.7231 3.52816 12.4626C3.26801 12.202 3.26832 11.7799 3.52886 11.5197L7.05509 7.99875L3.5304 4.47087C3.27017 4.2104 3.27036 3.78829 3.53083 3.52806C3.79129 3.26783 4.2134 3.26802 4.47364 3.52848L7.9986 7.05664L11.5159 3.54459C11.7764 3.28444 12.1985 3.28475 12.4587 3.5453C12.7188 3.80584 12.7185 4.22795 12.458 4.4881L8.94099 7.99988Z" fill="#44444F"></path></svg>
                                        </li>
                                    </div>
                                )
                            })}


                        </ul>
                    </div> : ''}
                    <div className="def-table pr-24">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile No.</th>
                                    {/* <th>Status</th> */}
                                    <th>Role </th>
                                    <th>Modified Date</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {userDetails && userDetails.length > 0 ? userDetails.map((user, index) => {
                                    const { uuid, name, email, mobile, is_active, role_name, role_id, role_uuid, created_on, is_owner, sms_notification, new_lead_notification, sv_notification } = user;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">
                                                {name.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className='text-capitalize'>{name}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='text-capitalize three-dots'>{name}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className='text-capitalize'>{name}</span>
                                                }
                                            </td>
                                            <td>
                                                {email.length > 20 ?
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={
                                                            <Tooltip id="button-tooltip" >
                                                                <div className='info-msg'>
                                                                    {/* <h4>Total Leads</h4> */}
                                                                    <p className=''>{email}</p>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span className='three-dots'>{email}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span className=''>{email}</span>
                                                }
                                            </td>
                                            <td>{mobile ? mobile : "-"}</td>
                                            {/* <td> <CustomBadge
                                            value={is_active}
                                            label={{ true: "Active", false: "In-Active" }}
                                            reverse={false}
                                        />
                                        </td> */}
                                            <td className="">{role_name || '-'}</td>
                                            <td>{createDatewithTime(created_on)}</td>
                                            <td >
                                                <span className={`${getClassName(is_active ? 'active' : 'inactive')}`}>
                                                    {is_active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                            <td className="dropdown view-more ">
                                                <button
                                                    className="bg-tr border-0"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                            fill="#B5B5BE"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                            fill="#B5B5BE"
                                                        />
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                            fill="#B5B5BE"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu dropdown-menu-right w-auto"
                                                    aria-labelledby="dropdownMenuButton"
                                                    onClick={() => setSelectedUser({ uuid, name, mobile, email, role: { label: role_name, value: role_uuid } })}
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => resetPassword(uuid)}
                                                    >
                                                        Reset Password
                                                    </a>
                                                    {/* {ROLE_NAME === 'ADMIN' && <a */}
                                                    {((!isRolesModule && ROLE_NAME === 'ADMIN') || allowedPermissions?.includes('add_new_user')) && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => {
                                                                setShowUserModal(true)
                                                            }
                                                        }
                                                    >
                                                        Edit User
                                                    </a>}
                                                    {/* {(USER_UUID !== uuid || !is_owner) && !is_active && <a */}
                                                    {(allowedPermissions?.includes('add_new_user') || (!isRolesModule && !is_owner || (USER_UUID !== uuid || !is_owner))) && !is_active && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => handleActive(uuid)}
                                                    >
                                                        Activate User
                                                    </a>}

                                                    {/* {(USER_UUID !== uuid || !is_owner) && is_active && <a */}
                                                    {((!isRolesModule ||
                                                        (role_name !== 'Super Admin' && allowedPermissions?.includes('add_new_user'))) &&
                                                        (USER_UUID !== uuid || !is_owner)) && is_active &&
                                                        <a
                                                            className="dropdown-item "
                                                            data-toggle="modal"
                                                            data-target="#exampleModal"
                                                            onClick={() => handleInactive(uuid)}
                                                        >
                                                            Deactivate User
                                                        </a>}

                                                    {/* {ROLE_NAME === 'ADMIN' && <a */}
                                                    {((!isRolesModule && ROLE_NAME === 'ADMIN') || allowedPermissions?.includes('edit_sms_notifications')) && <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => handleUpdateSMSNotification(uuid, !sms_notification)
                                                        }
                                                    >
                                                        {`${sms_notification ? 'Disable' : 'Enable'} All SMS`}
                                                    </a>}

                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => handleUpdateNewLeadNotification(uuid, !new_lead_notification)
                                                        }
                                                    >
                                                        {`${new_lead_notification ? 'Disable' : 'Enable'} New Lead Notification`}
                                                    </a>

                                                    <a
                                                        className="dropdown-item"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={
                                                            () => handleUpdateSVLeadNotification(uuid, !sv_notification)
                                                        }
                                                    >
                                                        {`${sv_notification ? 'Disable' : 'Enable'} SV Lead Notification`}
                                                    </a>

                                                    {/* {(USER_UUID !== uuid || !is_owner) && <a */}
                                                    {((!is_owner && USER_UUID !== uuid) && (!isRolesModule || allowedPermissions?.includes('delete_user'))) && <a
                                                        className="dropdown-item del-menu"
                                                        data-toggle="modal"
                                                        data-target="#exampleModal"
                                                        onClick={() => setShowDeleteUser(true)}
                                                    >
                                                        Delete User
                                                    </a>}
                                                </div>

                                            </td>
                                        </tr>
                                    )
                                }) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <UserLimitExeedModal show={false} /> */}
            </div>
            {showFilters && <UserFilters
                show={showFilters}
                setShow={setShowFilters}
                filtersData={filters}
                setFiltersData={setFilters}
            />}

            {/* <PaginationFooter
                countPerPage={countPerPage}
                setCountPerPage={setCountPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={100}
            /> */}
        </>
    )
}
