import moment from 'moment'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { createDatewithTime } from "../../helpers/date"
import { toINRFormat } from '../../helpers/helpers'
import { MAP_CURRENCY_NUMBER_TO_TEXT } from '../../utils/constants'
import { getSelectedValue } from "../../utils/selectedOptionUtils"
import DynamicIntent from "./TableColumns/DynamicIntent"
import LeadName from "./TableColumns/LeadName"
import MobileNo from "./TableColumns/MobileNo"
import NotesDropDown from './TableColumns/NotesDropDown'
import Projects from "./TableColumns/Projects"
import SourceLabel from "./TableColumns/SourceLabel"
import StatusDropDown from "./TableColumns/StatusDropDown"
import SharedWithUserName from './TableColumns/SharedWithUserName'
import ReengagedSource from './TableColumns/ReengagedSource'
import TooltipText from '../Tooltip'

const SVTYPES = {
  offline: 'Onsite',
  online: 'Video Call'
}

const RenderTableData = ({
  item,
  data,
  updateData,
  intentStatuses,
  statuses,
  leadData,
  agencyList,
  sourceStatuses,
  selectSingleUserId,
  setFollowupModal,
  setFollowupData,
  showSvCompleteModal,
  showSiteVisitModal,
  selectCurrentLead,
  getBookingDetails,
  tab,
  userProfile,
  setShowShareLeadAccessModal,
  setSelectedItem,
  setSelectedLead,
  setSVCompletedModal,
  setSiteVisitModal,
  handleOpenVerifyPage
}) => {
  if (item.label === 'View Lead Share' && !userProfile?.allowed_modules?.includes('lead_sharing')) {
    return null
  }

  const rendering = () => {
    switch (item.label) {
      case 'Name':
        return <td id='lead_name' className='sticky-td'>
          <LeadName
            data={data}
            intent={data.intent}
            intentStatuses={intentStatuses}
          />
        </td>

      case 'Mobile No.':
        return (
          <td className={`pr4 ${userProfile?.company_id === 581 ? 'sticky left-[252px]' : ''}`} style={{ maxWidth: '200px', overflow: 'hidden', }}>

            {<MobileNo mobileNo={data.mobile} />}
          </td>)

      case 'Booking/EOI Date':
        return <td >
          {createDatewithTime(new Date(data.booking_date || data.eoi_date))}
        </td>
      case 'Followup Date':
        return <>
          {tab === 'BOOKINGS' && <td>
            {data.followup_date && (getSelectedValue(data.status, statuses).label === 'EOI Completed' || getSelectedValue(data.status, statuses).label === 'Token Done') ? <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip" className='' >
                  <div className='info-msg'>
                    <p className='d-flex fz18'>Edit Date</p>
                  </div>
                </Tooltip>
              }
            >

              <a bsstyle="default" className='cursor-pointer text-black' onClick={() => {
                selectSingleUserId(data)
                const statusVal = getSelectedValue(data.status, statuses)
                if (statusVal.label === 'EOI Completed' || statusVal.label === 'Token Done') {
                  setFollowupModal(true)
                }
              }}>
                {createDatewithTime(new Date(data.followup_date))}
              </a>
            </OverlayTrigger> : '-'}
          </td>}

          {tab === 'SITEVISITS' && <td>
            {data.followup_date ? <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip" className='' >
                  <div className='info-msg'>
                    <p className='d-flex fz18'>Edit Date</p>
                  </div>
                </Tooltip>
              }
            >
              <a bsstyle="default" className={`${(moment(data.followup_date) < moment()) ? "red-color" : "text-black "} cursor-pointer`}
                onClick={() => {
                  selectCurrentLead(data)
                  const statusVal = getSelectedValue(data.status, statuses)
                  if (statusVal.label === 'SV Completed') {
                    showSvCompleteModal(true)
                  } else {
                    showSiteVisitModal(true)
                  }
                }}>
                {createDatewithTime(new Date(data.followup_date))}
              </a>
            </OverlayTrigger> : '-'}
          </td>}

          {tab === 'FOLLOWUPS' && <td>
            {data.followup_date ? <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip" className='' >
                  <div className='info-msg'>
                    <p className='d-flex fz18'>Edit Date</p>
                  </div>
                </Tooltip>
              }
            >

              <a bsstyle="default" className={`${(moment(data.followup_date) < moment()) ? "red-color " : "text-black "} cursor-pointer`} onClick={() => {
                setFollowupData(data, true)
              }}>
                {createDatewithTime(new Date(data.followup_date))}
              </a>
            </OverlayTrigger> : '-'}
          </td>}
        </>

      case 'Site Visit Date':
        return <td>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" className='' >
                <div className='info-msg'>
                  <p className='d-flex fz18'>Edit Date</p>
                </div>
              </Tooltip>
            }
          >
            <a bsstyle="default" className={`${(moment(data.sv_schedule_date) < moment() && !data.completed_time) ? "red-color" : "text-black "} cursor-pointer`}
              onClick={() => {
                selectCurrentLead(data)
                const statusVal = getSelectedValue(data.status, statuses)
                if (statusVal.label === 'SV Completed') {
                  showSvCompleteModal(true)
                } else {
                  showSiteVisitModal(true)
                }
              }}>
              {data.completed_time ? createDatewithTime(new Date(data.completed_time)) : createDatewithTime(new Date(data.sv_schedule_date))}
            </a>
          </OverlayTrigger>
        </td>

      case 'Status Marked Date':
        return (
          <td>
            {/* dead_lead_date changed to => status_changed_on */}
            {data['status_changed_on'] ? createDatewithTime(new Date(data['status_changed_on'])) : '-'}
          </td>
        )
      case 'Updated Date':
        return <td>{data[item.value] ? createDatewithTime(new Date(data[item.value])) : '-'}</td>

      case 'Project':
        return (
          <td>
            <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
              <Projects
                projectsArr={data.project?.filter((project) => project !== null)}
              />
            </div>
          </td>
        )

      case 'Status':
        return (
          <td>
            <StatusDropDown
              value={getSelectedValue(data.status, statuses)}
              options={statuses}
              id={data.id}
              reloadData={updateData}
              leadName={data.name}
              intentOptions={intentStatuses}
              data={data}
              handleOpenVerifyPage={handleOpenVerifyPage}
            />
          </td>)

      case 'Intent':
        return (
          <td>
            <DynamicIntent
              selectedIntent={data.intent}
              intentOptions={intentStatuses}
              reloadData={updateData}
              id={data.id}
              data={data}
              status={getSelectedValue(data.status, statuses)}
              statusName={data.status_name}
              rowLength={leadData.length}
              tableName='AllLead'
            />
          </td>)

      case 'Reasons':
        return (
          <td >
            {data.dead_reason ? <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip" >
                  <div className='info-msg'>
                    {/* <h4>Total Leads</h4>*/}
                    <p className='text-capitalize'>{data.dead_reason}</p>
                  </div>
                </Tooltip >
              }
            >
              <span className='three-dots cursor-pointer'>{data.dead_reason}</span>
            </OverlayTrigger > : '-'}
          </td >)

      case 'Note':
        return (
          <td>
            <div className='view-notes'>
              {tab === 'BOOKINGS' ?
                <NotesDropDown
                  note={data.booking_remarks || data.eoi_remarks}
                  date={data.followup_date ? data.booking_created_time : data['Booking Created Date']}
                /> :
                <NotesDropDown
                  note={data.remarks || data.completed_notes || data.sv_remarks || data.followup_remarks}
                  date={data['Event Created Date']}
                />
              }
            </div>
          </td>)

      case 'Source':
        return (
          <td>
            <SourceLabel
              allSources={sourceStatuses}
              sources={data.source}
            />
          </td>)

      case 'City':
        return <td>{data.city ? data.city : '-'}</td>

      case 'Assigned User':
        return <td>
          {data.assigned_to?.length > 20 ?
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                  <div className='info-msg'>
                    {data.assigned_to || '-'}
                  </div>
                </Tooltip>
              }
            >

              <span className='three-dots'>{data.assigned_to || '-'}</span>
            </OverlayTrigger>
            :
            <span className=''>{data.assigned_to || '-'}</span>
          }

        </td>

      case 'Marketing Agency':
        return agencyList.length > 0 && <td className='text-capitalize'>
          {(data.landing_page_agency_name || data.fb_agency_name) ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className=''>{data.landing_page_agency_name || data.fb_agency_name || '-'}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.landing_page_agency_name || data.fb_agency_name || '-'}</span>
          </OverlayTrigger > : '-'}
        </td>

      case 'Date':
        return <td>{data.Date ? createDatewithTime(new Date(data.date)) : '-'}</td>

      case 'Created Date':
        return <td>{data.created_date ? createDatewithTime(new Date(data.created_date)) : '-'}</td>

      case 'Purpose':
        return <td>{data.purpose || '-'}</td>

      case 'Preferred Location':
        return <td>
          {data.preferred_location ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className='text-capitalize'>{data.preferred_location}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.preferred_location}</span>
          </OverlayTrigger > : '-'}
        </td>
      case 'Email':
        return <td>

          {data.email ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className=''>{data.email}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.email}</span>
          </OverlayTrigger > : '-'}
        </td>
      case 'Budget':
        return <td>
          {`${data.minimum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[data.minimum_budget]} ${data.maximum_budget ? `- ${MAP_CURRENCY_NUMBER_TO_TEXT[data.maximum_budget]}` : ''}` : '-'}`}

          {/* {`${data.minimum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[data.minimum_budget]}` : ''} - ${data.maximum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[data.maximum_budget]}` : ''}`} */}
        </td>
      case 'Property Type':
        return <td>{data.property_category || '-'}</td>
      case 'Configuration Type':
        return <td>

          {data.configuration ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className=''>{data.configuration}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.configuration}</span>
          </OverlayTrigger > : '-'}
        </td>
      case 'Size':
        return <td>
          {data.carpet_area ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className=''>{`${data.carpet_area}  ${data.carpet_area_unit}`}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.carpet_area ? `${data.carpet_area}  ${data.carpet_area_unit}` : '-'}</span>
          </OverlayTrigger > : '-'}
        </td>
      case 'Requirement Type':
        return <td>{data.property_type || '-'}</td>
      case 'Funding Source':
        return <td>{data.funding_source || '-'}</td>
      case 'Employment Type':
        return <td>{data.employement_type || '-'}</td>
      case 'Facing':
        return <td>{data.facing || '-'}</td>
      case 'Customer Address':
        return <td>
          {data.current_residence ? <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip" >
                <div className='info-msg'>
                  {/* <h4>Total Leads</h4>*/}
                  <p className='text-capitalize'>{data.current_residence || '-'}</p>
                </div>
              </Tooltip >
            }
          >
            <span className='three-dots cursor-pointer'>{data.current_residence || '-'}</span>
          </OverlayTrigger> : '-'}

        </td>
      case 'Assigned Date':
        return <td>{createDatewithTime(data.system_modified_time) || '-'}</td>
      case 'Possession':
        return <td>{data.possession || '-'}</td>
      case 'Alt. Mobile':
        return <td>
          <a href={`tel:${data.alternate_mobile}`} style={{ color: '#171725' }}>{data.alternate_mobile || '-'}</a>
        </td>

      case 'Referred By':
        return <td>{data.referred_by || '-'}</td>
      case 'Industry':
        return <td>{data.industry || '-'}</td>
      case 'View Lead Share':
        return <td>
          {
            data.shared_with_count > 0 ?
              <div className='view-notes'>
                <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                  onClick={() => {
                    setSelectedItem(data)
                    setShowShareLeadAccessModal(true)
                  }}
                >
                  View
                </button>
              </div>
              : '-'
          }
        </td>
      case 'Marked By':
        return <td>
          {data?.status_changed_by_name || '-'}
        </td>
      case 'Marked Date':
        return <td>
          {
            data?.status_changed_on ? createDatewithTime(new Date(data?.status_changed_on)) : '-'
          }
        </td>
      case 'Lead Access Validtiy':
        return <td>{data.validity ? `${data?.validity} ${data.validity_unit}` : '-'}</td>
      case 'Access':
        return <td className='capitalize'>{data?.access ? data?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td>
      case 'Last Note':
        return <td>
          {data?.last_note ? <TooltipText title={data?.last_note}>
            <div className='w-[150px] truncate'>{data?.last_note}</div>
          </TooltipText> : '-'}
        </td>

      case 'Shared With User':
        return <td>
          {data?.shared_with_names ? <SharedWithUserName shareWithNameArr={data.shared_with_names} /> : '-'}
        </td>
      case 'Reason':
        return <td>{data?.followup_info || '-'}</td>
      case 'SV Duration':
        return <td>
          <button onClick={() => {
            setSelectedLead(data);
            setSVCompletedModal(true);
          }}>
            {data?.sv_duration ? `${data.sv_duration} ${data.sv_duration_unit}` : '-'}
          </button>
        </td>
      case 'Type of Site Visit':
        return <td className='capitalize'>
          <button onClick={() => {
            setSelectedLead(data);
            setSiteVisitModal(true);
          }}>
            {data?.sv_type ? SVTYPES[data?.sv_type] : 'Onsite'}
          </button>
        </td>
      case 'SV Project':
        return <td>
          <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
            <Projects
              projectsArr={data?.project_names?.filter((project) => project !== null)}
            />
          </div>
        </td>
      case 'Re-Engaged Sources':
        return <td>
          {(data?.is_re_engaged || data?.re_engaged_sources?.length) ? <ReengagedSource uuid={data.id} sources={data?.re_engaged_sources || []} /> : '-'}
        </td>
      case 'RM':
        return <td>{data?.rm_name || '-'}</td>
      case 'CP Firm':
        return <td>{data?.broker_company_name || '-'}</td>
      case 'CP User':
        return <td>{data?.broker_user_name || '-'}</td>
      case 'Tag Expiry':
        return <td>{moment(data?.tag_expiry).diff(moment(), 'days') > 0 ? moment(data?.tag_expiry).diff(moment(), 'days') + ' days' : data?.last_tag_cp_user ? 'Expired' : '-'}</td>
    }
  }

  return <>{rendering()}</>;
}

export default RenderTableData;
