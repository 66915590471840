import React, { useContext, useState, useEffect } from 'react';


import { Formik } from 'formik';

import moment from 'moment';

import { toast } from 'react-toastify';

import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ReactComponent as PlusPrimaryIcon } from '../../../assets/icons/PlusPrimary.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import Consumer from '../../../helpers/context';
import { Oval } from 'react-loader-spinner';
import InputSelect from '../../../components/InputGroup/InputSelect';
import DateTimePicker from '../../../components/InputGroup/DateTime';
import SliderModal from '../../../components/Modals/SliderModal';
import { insertLead } from '../../../services/private/borker.service';
import InputText from '../../../components/InputGroup/InputText';
import { validityUnit } from '../../../helpers/enums';
import { getUsersForSettings } from '../../../services/private/company.service';
import { createLeadSchema } from '../../../validators/leads.validator';

export default function CreateLeadModal({
    show,
    setShow,
    selectedLead,
    getEvents,
    allUsers
}) {

    const [followupData, setFollowupData] = useState({});

    // this is a hack update it when you have time :) @ankit
    const [selectedLeadId, setSelectedLeadId] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [allUsers, setAllUsers] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    // const [assignedTo, setAssignedTo] = useState();

    let { companyConfig } = useContext(Consumer);

    // console.log(selectedLead, 'selectedLead')
    // const getAllUsers = () => {
    //     getUsersForSettings().then(res => {
    //         if (res.status === 200) {
    //             const userData = res.data.data.map((data) => {
    //                 return {
    //                     label: data.name + " - " + data.email,
    //                     // label: data.name,
    //                     value: data.id
    //                 }
    //             })
    //             setAllUsers(userData)
    //         }
    //     })
    // }

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);

        }
    }, [companyConfig]);

    // useEffect(() => {
    //     getAllUsers()
    // }, [])

    const updateFollowupCall = async (scheduleDate, remarks, intentId, assignTo, taggingValidity, taggingValidityUnit) => {
        setIsSubmitting(true);
        const payload = {
            followupScheduleDate: scheduleDate,
            remarks: remarks,
            intentId: intentId,
            assigend_to: assignTo,
            tagging_validity: taggingValidity,
            tagging_validity_unit: taggingValidityUnit
        }

        try {
            await insertLead(selectedLead.uuid, payload).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message);
                    setIsSubmitting(false);
                    getEvents();
                    localStorage.setItem('searchText', selectedLead?.customer_mobile)
                    window.open('/');
                    setIsSubmitting(false);
                    setShow(false);
                }
            })
        } catch (err) {
            toast.error(err.response.data.message)
            setIsSubmitting(false);
            setShow(false);
        }

    }

    const handleSumitForm = (values) => {
        // if (followupData && followupData.schedule_date && !values.remarks) {
        //     toast.error('Please Enter Comments');
        //     return;
        // }        
        updateFollowupCall(values.scheduleDate, values.remarks, values.intent?.value, values.assignTo?.value, values?.taggingValidity, values?.taggingValidityUnit)
    }


    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={
                `Create Lead`
            }
            body={
                <>
                    <Formik
                        initialValues={{
                            scheduleDate: moment().add('days', 1),
                            remarks: '',
                            intent: intentStatuses[0] || '',
                            assignTo: '',
                            taggingValidity: '30',
                            taggingValidityUnit: 'days'
                        }}
                        onSubmit={(values, actions) => {
                            handleSumitForm(values)
                        }}
                        validationSchema={createLeadSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    >
                        {
                            (props) => {
                                const { values, touched, errors, handleChange, handleBlur, handleReset, handleSubmit } = props;
                                return (
                                    <form onSubmit={handleSubmit} className='flex flex-col h-full overflow-hidden'>
                                        <div className='h-full overflow-auto !px-5 !pb-6'>

                                            <InputText
                                                label='Name'
                                                value={selectedLead?.customer_name}
                                                disable={true}
                                            />
                                            <InputText
                                                label='Mobile'
                                                value={selectedLead?.customer_mobile}
                                                disable={true}
                                            />

                                            <InputText
                                                label='Project'
                                                value={selectedLead?.project_name}
                                                disable={true}
                                            />
                                            <InputText
                                                label='CP Firm'
                                                value={selectedLead?.company_name || selectedLead?.broker_company_name}
                                                disable={true}
                                            />
                                            <InputText
                                                label='CP User'
                                                value={selectedLead?.broker_name}
                                                disable={true}
                                            />
                                            <div className='!my-4'>
                                                <label htmlFor="">CP lead Tagging</label>
                                                <div className='grid grid-cols-2 gap-2'>
                                                    <InputText
                                                        placeholder={'e.g. 30'}
                                                        value={values['taggingValidity']}
                                                        name={'taggingValidity'}
                                                        onChange={(e) => {
                                                            const value = {
                                                                target: {
                                                                    name: "taggingValidity",
                                                                    value: e.target.value
                                                                }
                                                            }
                                                            handleChange(value)
                                                            // setTaggingValidity(e.target.value)
                                                        }}
                                                    />
                                                    <InputSelect
                                                        inputclass={'m-0'}
                                                        placeholder={'Select Time'}
                                                        name={'taggingValidityUnit'}
                                                        value={values['taggingValidityUnit'] ? validityUnit.find(validity => validity.value === values['taggingValidityUnit']) : ''}
                                                        options={validityUnit}
                                                        onChange={(e) => {
                                                            const value = {
                                                                target: {
                                                                    name: "taggingValidity",
                                                                    value: e.value
                                                                }
                                                            }
                                                            handleChange(value)
                                                            // setTaggingValidityUnit(value.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <InputSelect
                                                label={'Select Closing Manger'}
                                                placeholder={'Select Closing Manger'}
                                                name='assignTo'
                                                options={allUsers}
                                                value={values['assignTo']}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "assignTo",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                    // if (value) {
                                                    //     setAssignedTo(value.value);
                                                    // } else {
                                                    //     setAssignedTo(null);
                                                    // }
                                                }}
                                                error={errors['assignTo']}
                                                isClearable={true}
                                                isMulti={false}
                                                required={true}
                                            />

                                            <DateTimePicker
                                                handleBlur={handleBlur}
                                                onValueChange={(e) => {
                                                    const changeVal = {
                                                        target: {
                                                            value: e,
                                                            name: "scheduleDate"
                                                        }
                                                    }
                                                    handleChange(changeVal)
                                                }}
                                                label="Select Followup Date & Time"
                                                name="scheduleDate"
                                                error={errors['scheduleDate']}
                                                value={values['scheduleDate'] ? values['scheduleDate'] : ''}
                                            />

                                            <InputSelect
                                                index={4}
                                                name="intent"
                                                maxMenuHeight="150px"
                                                error={errors['intent']}
                                                touched={touched['intent']}
                                                options={intentStatuses}
                                                placeholder="Select Intent"
                                                value={values.intent}
                                                onChange={(e) => {
                                                    const value = {
                                                        target: {
                                                            name: "intent",
                                                            value: e
                                                        }
                                                    }
                                                    handleChange(value)
                                                }}
                                                label='Intent'
                                            />


                                            <div className='flex items-center justify-between !mt-4 !mb-2'>
                                                <label className='m-0'>Comments</label>
                                                <button
                                                    type='button'
                                                    className='!border !border-grayLight rounded text-sm !text-black700 inter px-2.5 !gap-x-1 py-1 flex items-center flex-nowrap !bg-white hover:!bg-black200'
                                                    onClick={() => {
                                                        const value = {
                                                            target: {
                                                                name: "remarks",
                                                                value: ''
                                                            }
                                                        }
                                                        handleChange(value)
                                                    }}
                                                >Clear <CloseIcon className='h-3 w-3' /></button>
                                            </div>
                                            <textarea
                                                className="form-control mb-16"
                                                name="remarks"
                                                value={values['remarks']}

                                                placeholder="Enter any follow up Comments..."
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                defaultValue={followupData.remarks}
                                            >

                                            </textarea>


                                        </div>
                                        <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                                            <button
                                                type='button'
                                                className="cancel outline-btn"
                                                onClick={() => setShow(false)}
                                            >
                                                Cancel
                                            </button>
                                            {!isSubmitting && <button
                                                className="pr-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Create Lead
                                            </button>}
                                            {isSubmitting && <button className="pr-btn flex items-center justify-center" type='button'>
                                                <Oval
                                                    height={24}
                                                    width={24}
                                                    color="#ffffff"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#ffffff"
                                                    strokeWidth={4}
                                                    strokeWidthSecondary={4}
                                                />
                                            </button>}
                                        </div>
                                    </form>
                                )
                            }}
                    </Formik>

                </>
            }
        />
    )
}