import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';

export async function getUnits(uuid, perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.getUnits}/${uuid}`, { perPage, pageNumber }, payload, null)
}

export async function getUnitsByMultipleProjectIds(perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.getUnitsByMultipleProjectIds}`, { perPage, pageNumber }, payload, null)
}



export async function updateUnit(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateUnitByUuid}/${uuid}`, null, payload, null)
}

export async function getUnitStatusEnum() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getUnitStatusEnum}`, null, null, null)
}


export async function getUnitBooking(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getUnitBooking}/${uuid}`, null, null, null)
}

export async function insertBooking(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.insertBookingDone}/${uuid}`, null, payload, null)
}

export async function bookingAdditionalInfo(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.bookingAddAdditionalInfo}/${uuid}`, null, payload, null)
}

export async function insertEOIDone(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.insertEOI}/${uuid}`, null, payload, null)
}

export async function eoiAdditionalInfo(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.addAdditionalInfo}/${uuid}`, null, payload, null)
}

export async function updateBookingUnit(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setBookingUnitByBookingId}/${uuid}`, null, payload, null)
}

export async function updateBrokerDetails(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setBrokerDetails}/${uuid}`, null, payload, null)
}

export async function updateBookingOtherDetails(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setBookingOtherDetails}/${uuid}`, null, payload, null)
}

export async function updateBookingApprovalStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.changeBookingApprovalStatus}/${uuid}`, null, payload, null)
}

export async function updateBookingPaymentPlan(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setPaymentPlan}/${uuid}`, null, payload, null)
}

export async function saveTransaction(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.saveTransaction}/${uuid}`, null, payload, null)
}

export async function getDemandDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getDemandDetail}/${uuid}`, null, null, null)
}

export async function eoiSaveTransaction(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.eoiSaveTransaction}/${uuid}`, null, payload, null)
}

export async function updateEOIUnit(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setEOIUnitByEOIId}/${uuid}`, null, payload, null)
}

export async function updateEOIBrokerDetails(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setEOIBrokerDetails}/${uuid}`, null, payload, null)
}

export async function updateEOIOtherDetails(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setEOIOtherDetails}/${uuid}`, null, payload, null)
}

export async function getBookingDoneDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getBookingDoneDetails}/${uuid}`, null, null, null)
}

export async function getBookingDoneDetailsByLeadId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getBookingDoneDetailsByLeadId}/${uuid}`, null, null, null)
}

export async function updateEOIApprovalStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.changeEOIApprovalStatus}/${uuid}`, null, payload, null)
}

export async function changeBookingStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.changeBookingStatus}/${uuid}`, null, payload, null)
}

export async function changeEoiStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.changeEoiStatus}/${uuid}`, null, payload, null)
}

export async function getEOIDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getEOIDetails}/${uuid}`, null, null, null)
}

export async function getDetailsByLeadId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getDetailsByLeadId}/${uuid}`, null, null, null)
}

export async function updatePreferredUnit(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updatePreferredUnit}/${uuid}`, null, payload, null)
}

export async function updateBookingPreferredUnit(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateBookingPreferredUnit}/${uuid}`, null, payload, null)
}

export async function updateUnitAvailability(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateUnitAvailability}/${uuid}`, null, payload, null)
}

export async function getUnitPricing(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getUnitPricing}/${uuid}`, null, null, null)
}

export async function updateBookingPricing(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setBookingPricing}/${uuid}`, null, payload, null)
}

export async function updateEOIPricing(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setEOIPricing}/${uuid}`, null, payload, null)
}
export async function updateEOIPaymentPlan(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.setEoiPaymentPlan}/${uuid}`, null, payload, null)
}

export async function getBookingPaymentMilestonesByEOIId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getBookingPaymentMilestonesByEOIId}/${uuid}`, null, null, null)
}
export async function raiseEOIDemand(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.raiseEOIDemand}/${uuid}`, null, payload, null)
}

export async function getEOIBookingPricing(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getEOIBookingPricing}/${uuid}`, null, null, null)
}

export async function getDemandDetailByEOI(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getDemandDetailByEOI}/${uuid}`, null, null, null)
}

export async function getPendingDemandByEOIId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getPendingDemandByEOIId}/${uuid}`, null, null, null)
}

export async function getEOIForDiscount(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getEOIForDiscount}/${uuid}`, null, null, null)
}


//  Collections api

export async function getBookingsForCollectionManager(uuid, perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.getBookingsForCollectionManager}/${uuid}`, { perPage, pageNumber }, payload, null)
}
export async function getCollectionsByProjectId(uuid, perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.getCollectionsByProjectId}/${uuid}`, { perPage, pageNumber }, payload, null)
}
export async function getDemandsByProjectId(uuid, perPage, pageNumber, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.getDemandsByProjectId}/${uuid}`, { perPage, pageNumber }, payload, null)
}

export async function getBookingPaymentMilestonesByBookingId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getBookingPaymentMilestonesByBookingId}/${uuid}`, null, null, null)
}

export async function raiseDemand(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.raiseDemand}/${uuid}`, null, payload, null)
}

export async function getCollectionManagerStats(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getCollectionManagerStats}/${uuid}`, null, null, null)
}

export async function getPendingDemandByBookingId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getPendingDemandByBookingId}/${uuid}`, null, null, null)
}

export async function addCollection(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.addCollection}/${uuid}`, null, payload, null)
}

export async function getCollectionDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getCollectionDetails}/${uuid}`, null, null, null)
}

export async function updateCollectionStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateCollectionStatus}/${uuid}`, null, payload, null)
}

export async function getCollectionByBookingId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getCollectionByBookingId}/${uuid}`, null, null, null)
}

export async function getCollectionDetailStat(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getCollectionDetailStat}/${uuid}`, null, null, null)
}

export async function getRaisedDemands(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getRaisedDemands}/${uuid}`, null, null, null)
}
export async function getPaymentLedger(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getPaymentLedger}/${uuid}`, null, null, null)
}

export async function getAllBookingDocs(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getAllBookingDocs}/${uuid}`, null, null, null)
}


export async function addDebitTransaction(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.addDebitTransaction}/${uuid}`, null, payload, null)
}

export async function addPDC(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.addPDC}/${uuid}`, null, payload, null)
}

export async function createConstructionUpdate(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.createConstructionUpdate}/${uuid}`, null, payload, null)
}

export async function uploadCustomerBookingDocs(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.inventory.uploadCustomerBookingDocs}/${uuid}`, null, payload, null)
}


export async function deleteLeadBookingDocs(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.deleteLeadBookingDocs}`, null, payload, null)
}

export async function updateConstructionUpdate(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateConstructionUpdate}/${uuid}`, null, payload, null)
}

export async function getConstructionUpdate(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getConstructionUpdate}/${uuid}`, null, null, null)
}
export async function getBankDetails(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getBankDetails}/${uuid}`, null, null, null)
}

export async function updateDemand(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.inventory.updateDemand}/${uuid}`, null, payload, null)
}

export async function getMilestoneByDemand(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getMilestoneByDemand}/${uuid}`, null, null, null)
}

export async function getTransactionProof(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.getTransactionProof}/${uuid}`, null, null, null)
}

export async function generateDemandPdf(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.inventory.generateDemandPdf}/${uuid}`, null, null, null)
}