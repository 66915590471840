import React, { useEffect, useState } from 'react';
import { ReactComponent as DownICon } from '../../assets/icons/Down.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/GrayMenu.svg';
import InputText from '../InputGroup/InputText';
import InputSelect from '../InputGroup/InputSelect';
import SearchInput from '../InputGroup/SearchInput';
import { getUnits, updateEOIUnit, updatePreferredUnit } from '../../services/private/inventory.service';
import InputTextarea from '../InputGroup/InputTextarea';
import { getConfigString, getPriceFromConfig, toINRFormat } from '../../helpers/helpers';
import { toast } from 'react-toastify';
import { getProjectConfigurations } from '../../services/public/public.service';
import InputRupees from '../InputGroup/InputRupees';

const getStatusClass = (status) => {
    switch (status) {
        case 'Available':
            return 'text-green bg-green100'
        case 'Unavailable':
            return 'text-black700 bg-black200'
        case 'Blocked':
            return 'text-primary bg-blueHighlight'
        case 'Pending confirmation':
            return 'text-primary bg-blueHighlight'
        case 'Booked':
            return 'text-white bg-primary'
    }
}

const configLabel = (item) => {
    let label = item.unit_configuration
    if (item.unit_type === 'Plot') {
        label = `${item.unit_type} (${item.plot_area} ${item.plot_area_unit})`
    } else {
        if (item.carpet_area) {
            label = `${item.unit_type} ${label} (${item.carpet_area} ${item.carpet_area_unit})`
        } else if (item.super_area) {
            label = `${item.unit_type} ${label}  (${item.super_area} ${item.super_area_unit})`
        } else if (item.built_up_area) {
            label = `${item.unit_type} ${label}  (${item.built_up_area} ${item.built_up_area_unit})`
        }
    }
    return label;
}

const UnitDetail = ({ setSteps, eoiId, selectedProject, setUnit, data, setUnitPreference, selectedBooking, basePriceConfigMap, basePriceData, setIsPrefferedUnit }) => {
    const [isSpanClassAdded, setIsSpanClassAdded] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
    const [unitDetails, setUnitDetails] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [previousSelectedUnit, setPreviousSelectedUnit] = useState({});
    const [unitSelection, setUnitSelection] = useState('');
    const [unitConfigure, setUnitConfigure] = useState('');
    const [unitType, setUnitType] = useState('');
    const [floor, setFloor] = useState('');
    const [block, setBlock] = useState('');
    const [unitNumber, setUnitNumber] = useState('');
    const [pricing, setPricing] = useState('');
    const [remarks, setRemarks] = useState('');
    const [arrangedUnit, setArrangedUnit] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('');
    const [selectedBlockIndex, setSelectedBlockIndex] = useState('')
    const [showAvailable, setShowAvailable] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [unitConfiguration, setUnitConfiguration] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)

    const getUnitDetails = async () => {
        const payload = {
            query: {
                searchText
            }
        }
        const res = await getUnits(selectedProject?.uuid, 500, 1, payload);
        if (res.data.status === 200) {
            let arrangedData = {}
            // let availCount = {}            
            res.data.data.units.forEach(item => {
                if (arrangedData[item.block || item.floor_no]) {
                    arrangedData[item.block || item.floor_no].push(item)
                } else {
                    arrangedData[item.block || item.floor_no] = [item]
                }
            })
            let filteredData = {}
            Object.keys(arrangedData).forEach(item => {
                const availableUnit = arrangedData[item].filter(unit => unit.status === 'Available');
                if (availableUnit.length > 0)
                    filteredData[item] = availableUnit;
            })
            setArrangedUnit(filteredData);
            setUnitDetails(arrangedData);
        }
    }

    const updateUnitSelection = () => {
        if ((selectedBooking && Object.keys(selectedBooking).length > 0) && (selectedBooking?.booking?.approval_status &&
            !(selectedBooking?.booking?.approval_status === 'rejected' || selectedBooking?.booking?.approval_status === 'cancelled'))
        ) {
            const { bookingUnit, booking } = selectedBooking;
            if (bookingUnit) {
                setSelectedUnit(bookingUnit)
                setPreviousSelectedUnit(bookingUnit);
                let filteredData = {};
                Object.keys(unitDetails).forEach(item => {
                    const availableUnit = unitDetails[item].filter(unit => unit.uuid === bookingUnit.uuid);
                    if (availableUnit.length > 0)
                        filteredData[item] = availableUnit;
                })
                if (Object.keys(filteredData).length) {
                    setArrangedUnit(filteredData)
                }

            } else if (booking?.preferred_unit_configuration) {
                getConfig();
                setUnitConfigure(booking?.preferred_unit_configuration)
                setUnitType(booking?.preferred_unit_type)
                setFloor(booking?.preferred_floor)
                setBlock(booking?.preferred_block)
                setUnitNumber(booking?.preferred_unit_no)
                setPricing(booking?.tentative_pricing)
                setRemarks(booking?.preferred_comments)
            }
        }
    }
    useEffect(() => {
        if ((selectedBooking && Object.keys(selectedBooking).length > 0) && (selectedBooking?.booking?.approval_status &&
            !(selectedBooking?.booking?.approval_status === 'rejected' || selectedBooking?.booking?.approval_status === 'cancelled'))
        ) {
            const { bookingUnit, booking } = selectedBooking;
            if (bookingUnit) {
                setSelectedUnit(bookingUnit)
                setPreviousSelectedUnit(bookingUnit);
                setUnitSelection('yes')
                let filteredData = {};
                Object.keys(unitDetails).forEach(item => {
                    const availableUnit = unitDetails[item].filter(unit => unit.uuid === bookingUnit.uuid);
                    if (availableUnit.length > 0)
                        filteredData[item] = availableUnit;
                })
                if (Object.keys(filteredData).length) {
                    setArrangedUnit(filteredData)
                }

            } else if (booking?.preferred_unit_configuration) {
                getConfig();
                setUnitConfigure(booking?.preferred_unit_configuration)
                setUnitType(booking?.preferred_unit_type)
                setFloor(booking?.preferred_floor)
                setBlock(booking?.preferred_block)
                setUnitNumber(booking?.preferred_unit_no)
                setPricing(booking?.tentative_pricing)
                setRemarks(booking?.preferred_comments)
                setUnitSelection('no');

            }
        }
    }, [selectedBooking, unitDetails, data]);

    useEffect(() => {
        if (selectedProject?.uuid) {
            getUnitDetails();
        }
    }, [selectedProject, searchText]);

    const toggleSpanClass = () => {
        setIsSpanClassAdded(!isSpanClassAdded);
    };
    const spanClassName = isSpanClassAdded ? 'rotate-180' : '';

    const toggleVisibility = (value, index, blockindex) => {
        setIsHidden(value);
        setSelectedIndex(!value ? index : '')
        setSelectedBlockIndex(!value ? blockindex : '')
    };

    const handleShowAvailable = (value) => {
        setShowAvailable(value);

        if (value) {
            let filteredData = {}
            Object.keys(unitDetails).forEach(item => {
                const availableUnit = unitDetails[item].filter(unit => unit.status === 'Available');
                if (availableUnit.length > 0) {
                    filteredData[item] = availableUnit;
                }
            })
            setArrangedUnit(filteredData);
        } else {
            setArrangedUnit({ ...unitDetails });
        }
    }

    // const divClassName = isHidden ? 'hidden' : '';

    const handleSubmit = async () => {
        if (!selectedUnit || !selectedUnit.uuid) {
            toast.warning('Please Select Unit.');
            return;
        }

        const payload = {
            unit: selectedUnit.uuid,
            status: "Blocked"
        }
        setLoading(true);
        const res = await updateEOIUnit(eoiId, payload);
        setLoading(false);
        if (res.data.status === 200) {
            setUnit(selectedUnit);
            setSteps('EOIPricing')
        }
    }

    const getConfig = async () => {
        const res = await getProjectConfigurations(selectedProject?.uuid);
        if (res.data.status === 200) {
            const mappedConfig = res.data.data.filter(i => i.unit_configuration)
                .map(item => ({ label: `${configLabel(item)}`, value: item.id }))
            setUnitConfiguration(mappedConfig);
        }
    }

    const handleUpdatePrefferedUnit = async () => {
        if (!unitConfigure) {
            setError(true)
            return;
        } else {
            setError(false)
        }
        const payload = {
            preferred_unit_configuration: unitConfigure || null,
            preferred_unit_type: unitType || null,
            preferred_floor: floor || null,
            preferred_block: block || null,
            preferred_unit_no: unitNumber || null,
            tentative_pricing: pricing || null,
            preferred_comments: remarks,
            previous_booked_unit: previousSelectedUnit?.uuid || null
        }
        setLoading(true);
        const res = await updatePreferredUnit(eoiId, payload);
        setLoading(false);
        if (res.data.status === 200) {
            setUnitPreference('preffred');
            setSteps('Additional')
        }
    }

    return (
        <>
            <div className='!pt-5 sm:!px-6 px-3 pb-8 h-full overflow-auto'>
                <div className='flex items-center !mb-5'>
                    <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to select specific unit?</h2>
                    <div className='flex'>
                        <div className='mr-4 cursor-pointer'>
                            <input type="radio" id='unit_yes' name='unit_select' className='mr-2' value='yes' checked={unitSelection === 'yes'} onChange={() => {
                                setUnitSelection('yes');
                                setIsPrefferedUnit('yes');
                                setUnitConfigure('');
                                setUnitType('');
                                setFloor('');
                                setBlock('');
                                setUnitNumber('');
                                setPricing('');
                                setRemarks('')
                                if (Object.keys(selectedUnit).length === 0) {
                                    updateUnitSelection();
                                }
                            }} />
                            <label className='cursor-pointer font-semibold' htmlFor="unit_yes">Yes</label>
                        </div>
                        <div >
                            <input type="radio" id='unit_no' name='unit_select' value='no' className='mr-2' checked={unitSelection === 'no'} onChange={() => {
                                getConfig();
                                setSelectedUnit({});
                                setUnitSelection('no');
                                setIsPrefferedUnit('no');
                                if (!unitConfigure) {
                                    updateUnitSelection();
                                }
                            }} />
                            <label className='cursor-pointer font-semibold' htmlFor="unit_no">No</label>
                        </div>
                    </div>

                </div>
                {
                    unitSelection === 'no' && <div className='!mt-4'>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <InputSelect
                                label='Preferred Unit Configuration'
                                placeholder='2BHK (1273 sq.ft) '
                                inputclass='m-0 inter'
                                isMulti={false}
                                value={unitConfigure ? unitConfiguration.find(i => i.value == unitConfigure) : ''}
                                options={unitConfiguration}
                                onChange={(value) => setUnitConfigure(value.value)}
                                required={true}
                                error={error && !unitConfigure ? 'Please Select Preferred Unit Configuration' : ''}
                            />
                            <InputText
                                label="Preferred Unit Type"
                                placeholder="Enter Unit Type"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={unitType}
                                onChange={(e) => setUnitType(e.target.value)}
                            />
                            <InputText
                                label="Preferred Floor"
                                placeholder="Enter Preferred Floor"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={floor}
                                onChange={(e) => setFloor(e.target.value)}
                            />
                            <InputText
                                label="Preferred Block"
                                placeholder="Enter Preferred Block"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={block}
                                onChange={(e) => setBlock(e.target.value)}
                            />
                            <InputText
                                label="Preferred Unit No."
                                placeholder="Enter Preferred Unit No"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={unitNumber}
                                onChange={(e) => setUnitNumber(e.target.value)}
                            />
                            <InputRupees
                                label="Tentative Pricing"
                                placeholder="Enter Tentative Pricing"
                                type="text"
                                name="name"
                                inputclass='m-0 inter'
                                value={pricing}
                                handleBlur={() => { }}
                                onChange={(value) => setPricing(value)}
                            />
                            <InputTextarea
                                type="text"
                                label="Remarks"
                                placeholder="Enter Remarks"
                                name="name"
                                inputclass='m-0 inter'
                                row='2'
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}

                            />
                        </div>
                    </div>
                }

                {unitSelection === 'yes' && <>
                    <div className='flex md:items-center md:flex-row flex-col md:gap-0 !gap-4'>
                        <div className={`${!selectedBooking ? 'md:w-4/12' : 'W-full'}`}>
                            <h2 className='m-0 text-textblack text-lg font-semibold inter w-full'>All Units ({selectedProject?.name}) </h2>
                        </div>
                        {!(selectedBooking && Object.keys(selectedBooking).length > 0 && selectedBooking.bookingUnit) && <div className='md:w-8/12 flex md:items-center items-start  gap-x-3 sm:flex-row flex-col sm:gap-y-0 gap-y-3'>
                            <div className='!border border-grayLight rounded-lg  py-2.5 !px-4 !bg-white inline-flex items-center sm:w-[218px] sm:basis-[218px] sm:grow-0 sm:shrink-0 w-full justify-between'>
                                <h4 className='mb-0 text-xs font-semibold text-black700 inter'>Show all Available</h4>
                                <label className="relative inline-flex items-center cursor-pointer m-0">
                                    <input
                                        type="checkbox"
                                        value={showAvailable}
                                        className="sr-only peer"
                                        checked={showAvailable}
                                        onChange={() => handleShowAvailable(!showAvailable)}
                                        disabled={selectedBooking && Object.keys(selectedBooking).length > 0 && selectedBooking.bookingUnit}
                                    />
                                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:!bg-primary"></div>
                                </label>
                            </div>
                            <div className='w-full'>
                                <SearchInput
                                    placeholder={'Search Unit No'}
                                    searchText={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    handelClear={() => setSearchText('')}
                                    disabled={selectedBooking && Object.keys(selectedBooking).length > 0 && selectedBooking.bookingUnit}
                                />
                            </div>
                        </div>}
                    </div>

                    {
                        Object.keys(arrangedUnit).length > 0 ? Object.keys(arrangedUnit).map((floor, index) => (
                            <div className='!mt-4 ' key={`${floor}_${index}`}>
                                <div id="accordion" className='flex flex-col !gap-4'>
                                    <div className="!border border-grayLight rounded-xl">
                                        <div className="d-flex justify-between  !p-5" id="headingOne" data-toggle="collapse" data-target={`#collapseOne_${index}`} aria-expanded="true" aria-controls={`collapseOne_${index}`} onClick={toggleSpanClass}>
                                            <h5 className="mb-0 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] items-center inter">
                                                Block {floor}
                                            </h5>
                                            <div className='flex items-center gap-x-3 '>
                                                {/* <p className='m-0 text-black700 text-sm inter'>12/24 Available</p> */}
                                                <span className={spanClassName}><DownICon /></span>
                                            </div>
                                        </div>

                                        {arrangedUnit[floor] && arrangedUnit[floor].map((unit, unitIndex) => (
                                            <div id={`collapseOne_${index}`} className="collapse show" aria-labelledby="headingOne" data-parent="#accordion" key={`${unit.uuid}_${unitIndex}`}>
                                                {(selectedBlockIndex !== index && selectedIndex !== unitIndex) && <div className='!px-4 border border-grayLight !mx-4 rounded-lg !mb-4'>
                                                    <div className=' flex sm:items-center justify-between !pt-4 !pb-4 sm:flex-row flex-col'>
                                                        <div className='md:w-2/4'>
                                                            <h3 className='inter text-base font-semibold text-black700 m-0'>{unit?.name}</h3>
                                                            {/* <div className='sm:hidden bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md !mt-2 text-center'>Available</div> */}
                                                        </div>
                                                        <div className='md:w-2/4 flex items-center sm:justify-end justify-between gap-x-3 sm:!mt-0 !mt-4'>
                                                            <div className={`text-xs text-center rounded-lg p-1 capitalize ${getStatusClass(unit.status)}`}>
                                                                {unit.status}{`${(unit.status === 'Pending confirmation' || unit.status === 'Blocked') ? ' (' + unit.booking_stage + ')' : ''}`}
                                                            </div>
                                                            {unit.status === 'Available' && <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                                <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium'>Block Unit</p>
                                                                <input
                                                                    type="radio"
                                                                    name="unit"
                                                                    id=""
                                                                    disabled={unit.status !== 'Available'}
                                                                    checked={selectedUnit?.uuid === unit?.uuid}
                                                                    onChange={() => setSelectedUnit(unit)}
                                                                    className='sm:w-[22px] sm:h-[22px] w-5 h-5' />
                                                            </div>}
                                                            {/* <button><MenuIcon /></button> */}
                                                        </div>
                                                    </div>
                                                    <div className='!border-t !border-grayLight flex sm:items-center justify-between sm:flex-row flex-col sm:gap-y-0 gap-y-4 m-0 text-textblack inter text-sm font-medium !pt-3 !pb-4'>
                                                        <div className='inline-flex !gap-x-6 sm:flex-row flex-col sm:gap-y-0 !gap-4'>
                                                            <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.name || '-'}</h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Unit No.</p>
                                                            </div>
                                                            <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.block}</h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>{unit?.configuration?.unit_type === 'Plot' ? 'Block' : 'Tower'} </p>
                                                            </div>
                                                            {unit?.configuration?.unit_type !== 'Plot' && <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{unit?.floor_no}</h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Floor</p>
                                                            </div>}
                                                            <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>{getConfigString(unit?.configuration)} </h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Area</p>
                                                            </div>
                                                            {basePriceConfigMap && <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>₹ {toINRFormat(getPriceFromConfig(unit?.configuration, basePriceConfigMap[unit?.configuration?.id]))}</h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Price</p>
                                                            </div>}

                                                        </div>
                                                        {isHidden && <div>
                                                            <button onClick={() => toggleVisibility(false, unitIndex, index)} className='text-sm inter !text-primary mb-0 leading-5 font-medium text-right'>View Unit</button>
                                                        </div>}
                                                    </div>
                                                </div>}
                                                {(selectedBlockIndex === index && selectedIndex === unitIndex && !isHidden) && <div className={`!border !border-primary !px-4 !py-4 rounded-xl bg-[#F9FAFD] !mx-4 mb-4 `} >
                                                    <div className=' flex items-center justify-between !pb-4'>
                                                        <div className='w-2/4'>
                                                            <h3 className='inter text-base font-semibold text-black700 m-0'>{unit.name || '-'}</h3>
                                                        </div>
                                                        {/* <div className='w-2/4 flex items-center justify-end gap-x-3'>
                                                        <div className='bg-green100 text-xs inter font-semibold inter leading-4 !py-2 px-2.5 !text-green rounded-md'>Available</div>
                                                        {/* <div className='flex !border border-grayLight sm:gap-3 gap-1 items-center !py-1 !px-2.5 rounded'>
                                                                <p className='m-0 text-textblack inter sm:text-sm text-xs font-medium whitespace-nowrap'>Block Unit</p>
                                                                <input type="radio" name="" id="" className='sm:w-[22px] sm:h-[22px] w-5 h-5' />
                                                            </div>
                                                    </div> */}
                                                        <div className={`text-xs text-center rounded-lg p-1 capitalize ${getStatusClass(unit.status)}`}>
                                                            {unit.status}{`${(unit.status === 'Pending confirmation' || unit.status === 'Blocked') ? ' (' + unit.booking_stage + ')' : ''}`}
                                                        </div>
                                                    </div>
                                                    <div className='!mt-4'>
                                                        <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                                                            {unit?.name && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Unit Number</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.name || '-'}</p>
                                                            </div>}
                                                            {unit?.configuration && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Configuration</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{getConfigString(unit?.configuration)}</p>
                                                            </div>}
                                                            {unit?.block && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Block/Wing</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.block || '-'}</p>
                                                            </div>}
                                                            {unit?.floor_no && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Floor</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.floor_no || '-'} </p>
                                                            </div>}
                                                            {unit?.configuration?.unit_type && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Unit Type</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'> {unit?.configuration?.unit_type || '-'}</p>
                                                            </div>}
                                                            {/* <div>
                                                            <p className='!mb-1 text-sm text-black700'>No. of Units per Floor</p>
                                                            <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.floor_no || '-'}</p>
                                                        </div> */}
                                                            <div>
                                                                <p className='!mb-1 text-sm text-black700'>Furnishing</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.furnishing || '-'}</p>
                                                            </div>
                                                            {unit?.car_parking && <div>
                                                                <p className='!mb-1 text-sm text-black700'>Car Parking</p>
                                                                <p className='m-0 text-base font-semibold text-black700 inter'>{unit?.car_parking || '-'}</p>
                                                            </div>}
                                                            {basePriceConfigMap && <div>
                                                                <h4 className='inter text-black700 text-base font-semibold leading-6 mb-0'>₹ {toINRFormat(basePriceConfigMap[unit?.configuration.id])}</h4>
                                                                <p className='inter text-[10px] leading-3 uppercase text-black700 mb-0'>Unit Rate Price</p>
                                                            </div>}
                                                        </div>
                                                    </div>

                                                    <div className='mt-4 flex justify-end'>
                                                        <button onClick={() => toggleVisibility(true, unitIndex, index)} className='text-sm font-medium !text-primary inter'>Hide Unit</button>
                                                    </div>

                                                </div>}
                                            </div>
                                        ))
                                        }
                                    </div>


                                </div>
                            </div>
                        )) :
                            <div className='flex flex-col justify-center items-center h-[32vh]'>
                                <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
                                <h2 className="po black fz16 fw-po-medium mb-8"> No Units Found</h2>
                            </div>
                    }
                </>}
            </div >
            <div className='!px-6 !py-4 flex gap-3 sm:items-center justify-end shadow-topshadow sm:flex-row flex-col'>
                {/* <div>
                    <h3 className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ 64,00,000</h3>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For $Unit_Name</p>
                </div> */}
                <div className='flex gap-3 items-center justify-end'>
                    <button className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center inter' onClick={() => setSteps('EOIBasicDetail')}>Previous</button>
                    {unitSelection && <button
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary sm:w-[128px] sm:basis-[128px] sm:grow-0 sm:shrink-0 w-full basis-full h-[44px] text-center inter'
                        disabled={loading}
                        onClick={() => {

                            if (unitSelection === 'no') {
                                handleUpdatePrefferedUnit()
                            } else {
                                handleSubmit();
                            }
                        }}>Next</button>}
                </div>
            </div>
        </>
    )
}

export default UnitDetail
