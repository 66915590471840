import React, { useState, useEffect, useMemo, useContext } from 'react';
import DatePicker from 'react-datepicker';
import SliderModal from '../SliderModal';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from 'moment';
import Select from 'react-select';
import { getLeadsFilter, getProjectsForFilter } from '../../../services/private/leads.service';
import DynamicAccordion from './Dynamic.accordion';
import CheckAccordion from './Check.accordion';
import { getCompanyAgency, getCompanyConfig, getMyUsers, getPlusAppFilterUsers } from '../../../services/private/company.service';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { leadTypesEnum } from '../../../config/enums';
import { getAllCities, getAllTeams, getDeadReasons } from '../../../services/public/public.service';
import { getCustomerEnumsCall } from '../../../services/private/customer.service';
import { EOICancelReasonList, InProgessReasonsList, NotConnectedReasonsList, SVCancelledReasonList, approvalStatuses, budgetRange, employment_list, followupStatuses, svTypes } from '../../../utils/constants';
import StaticFieldAccordian from './StaticFieldAccordian';
import SizeFilterAccordian from './SizeFilterAccordian';
import { ALL_REGEX } from '../../../config/static';
import Consumer from '../../../helpers/context';
import { getAllJobs } from '../../../services/private/csv.service';
import InputText from '../../InputGroup/InputText';
import SearchInput from '../../InputGroup/SearchInput';
import CPUserAccordian from './CPUser.accordian';
import { getAssignedCPs } from '../../../services/private/borker.service';

const defaultStaticFilterList = [
    'Status',
    'Intent',
    'Project',
    'Source',
    'Dead Reasons',
    'Followup Date',
    'Site Visit Date',
    'Site Visit Completed Date',
    'Site Visit Completed',
    'Booking Date',
    'Eoi Date',
    'Token Date',
    'Assigned User',
    'Created Date',
    'Created Time',
    'Assigned Date',
    'City',
    'Marketing Agency',
    'Employment Type',
    'Facing',
    'Customer Address',
    'Preferred Location',
    'Possession',
    'Purpose',
    'Property Type',
    'Configuration Type',
    'Size',
    'Requirement Type',
    'Funding Source',
    'Budget',
    'Industry',
    'Referred By',
    'CSV Import',
    'SV Type',
    'Shared With',
    'Reasons',
    'SV Cancel Reasons',
    'In-Progress Reasons',
    'Not Connected Reasons',
    'Mobile No',
    'Re-Engaged Leads',
    'Facebook Forms',
    'Re-Engaged Date',
    'Re-assign Date',
    'Hide Team Leads',
    'Marked By',
    'Marked Date',
    'Booking Cancellation',
    'EOI Cancellation',
    'Token Cancellation',
    'Re-Engaged Source',
    'Sourcing manager',
    'CP User Code',
    'Teams',
    "Revisit",
    'CP Firm',
    'CP User'
];

const searchFilterkey = [
    'Assigned To',
    'Assigned User',

]
const sharedWithSearchKey = [
    'Shared With',
    'Marked By',
    'Sourcing Manager'
]

const OPTIONS = [
    { label: 'Sqft', value: 'sqft' },
    { label: 'Sq Yard', value: 'sq yard' },
    { label: 'Sqm', value: 'sqm' },
    { label: 'Yards', value: 'yards' },
    { label: 'Acres', value: 'acres' },
    { label: 'Hectares', value: 'hectares' },
    { label: 'Gaj', value: 'gaj' },
    { label: 'Biga', value: 'biga' },
]

export default function FilterModal({
    show,
    setShow,
    handleFilter,
    setFilterCount,
    filtersData,
    leadType,
    reAssignRole,
    setCurrentPage,
    filterToaster,
    propertyEnums,
    isRolesModule,
    allowedPermissions,
    fbFormList
}) {

    const [config, setConfig] = useState({
        "Intent": [],
        "Project": [],
        "Source": [],
        "Status": [],
        'Dead': []
    })
    // Input Values
    const [inputValues, setInputValues] = useState({
        "Name": "",
        "Mobile No": "",
        "Assigned To": "",
        City: '',
        'Shared With': '',
        Reasons: '',
        'Marked By': '',
        'Sourcing Manager': ''
    });

    const [inputValuesNotFound, setInputValuesNotFound] = useState({
        "Name": "",
        "Mobile No": "",
        "Assigned To": "",
        City: '',
        'Shared With': '',
        Reasons: '',
        'Marked By': '',
        'Sourcing Manager': '',
        'CP Firm': ''
    });

    const [deadLeadReasons, setDeadLeadReasons] = useState([]);

    const [searchResults, setSearchResults] = useState({
        "Name": [],
        "Mobile No": [],
        "Project": [],
        "Source": [],
        "Assigned To": [],
        Dead: [],
        City: [],
        'Shared With': [],
        Reasons: [],
        'Marked By': [],
        'Sourcing Manager': [],
        'CP Firm': []
    });

    const [searchDefultResults, setSearchDefaultResults] = useState({
        "Assigned To": [],
        "Assigned User": [],
        'Shared With': [],
        'Marked By': [],
        'Sourcing Manager': [],
        'CP Firm': []
    });

    // Filter Values
    const [name, setname] = useState([]);
    const [city, setCity] = useState([]);
    const [reason, setReason] = useState([]);
    const [mobile, setmobile] = useState([]);
    const [intent, setintent] = useState([]);
    const [project, setproject] = useState([]);
    const [status, setstatus] = useState([]);
    const [csvVal, setCSVVal] = useState([]);
    const [svTypeValue, setSVTypeValue] = useState([]);
    const [reengagement, setReengagement] = useState([]);
    const [svCompleted, setSvCompleted] = useState([])
    const [revisit, setRevisit] = useState([]);
    const [hideTeamLeads, setHideTeamLeads] = useState([]);
    const [source, setSource] = useState([]);
    const [reengageSource, setReengageSource] = useState([]);
    const [dead, setDead] = useState([]);
    const [filterDate, setFilterDate] = useState({});
    const [markedDate, setMarkedDate] = useState({});
    const [createdTime, setCreatedTime] = useState({})
    const [updatedDate, setUpdatedDate] = useState({})
    const [SVDate, setSVDate] = useState({});
    const [SVCompleteDate, setSVCompleteDate] = useState({});
    const [followupDate, setFollowupDate] = useState({})
    const [bookingDate, setBookingDate] = useState({})
    const [eoiDate, setEoiDate] = useState({})
    const [assign, setAssign] = useState([]);
    const [markedBy, setMarkedBy] = useState([]);
    const [sharedWith, setSharedWith] = useState([])
    const [agency, setAgency] = useState([]);
    const [bookingCancel, setBookingCancel] = useState([]);
    const [eoiCancel, setEoiCancel] = useState([]);
    const [agencyDetails, setAgencyDetails] = useState([]);
    const [filterSelected, setFilterSelected] = useState(false)
    const [purpose, setPurpose] = useState([])
    const [propertyType, setPropertyType] = useState([])
    const [requirementType, setRequirementType] = useState([]);
    const [funding, setFunding] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);
    const [facing, setFacing] = useState([]);
    const [possession, setPossession] = useState([]);
    const [budget, setBudget] = useState({});
    const [email, setEmail] = useState('');
    const [altMobile, setAltMobile] = useState('');
    const [configuration, setConfigurtion] = useState('');
    const [preferredLocation, setPreferredLocation] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [size, setSize] = useState({});
    const [industry, setIndustry] = useState([]);
    const [notConnectedReasons, setNotConnectedReasons] = useState([]);
    const [inProgressReasons, setInProgressReasons] = useState([]);
    const [svCancelReason, setSvCancelReason] = useState([]);
    const [referredBy, setReferredBy] = useState('');
    const [projects, setProjects] = useState([]);
    const [csvList, setCSVList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [defaultFilterList, setDefaultFilterList] = useState(defaultStaticFilterList);
    const [fbFormValue, setFbFormValue] = useState([]);
    const [reassignDate, setReassignDate] = useState([]);
    const [reengagedDate, setReengagedDate] = useState([]);
    const [brokerCode, setBrokerCode] = useState('');
    const [teamList, setTeamList] = useState([]);
    const [team, setTeam] = useState([]);
    const [selectedRM, setSelectedRM] = useState([]);
    const [brokerCompany, setBrokerCompany] = useState([]);
    const [cpUser, setCPUser] = useState([])
    const [brokerCompanyList, setBrokerCompanyList] = useState([]);

    const ROLE_NAME = localStorage.getItem('role');

    const { userProfile } = useContext(Consumer);

    const getAllDataJobs = () => {
        getAllJobs()
            .then((res) => {
                if (res.data.status === 200) {
                    // setMappedLeads(res.data.data)
                    const mappedData = res.data.data.map(item => ({ ...item, label: item.filename, value: item.job_id }))
                    setCSVList(mappedData)
                }
            })
            .catch((err) => {
                console.log({ err })
            })
    }

    //   handleChanges
    const handleInputChange = async (e) => {
        const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
        setInputValues(inputValuesData);
        // setFilterSelected(true)
        //if user make input field blank using backspace
        if ((searchFilterkey.includes(e.target.name) || sharedWithSearchKey.includes(e.target.name)) && !e.target.value) {
            setSearchResults({ ...searchResults, [e.target.name]: searchDefultResults[e.target.name] });
            return false
        }

        const fieldName = e.target.name;
        if (inputValuesData[fieldName].length < 3) {
            setSearchResults({ ...searchResults, [fieldName]: [] });
            return false
        };

        let filterData = [];
        if (fieldName === 'City') {
            filterData = await getAllCitiesByName(inputValuesData[fieldName])
        } else if (fieldName === 'CP Firm') {
            filterData = await getCompanyName(inputValues[fieldName]);
        } else {
            filterData = await getFilterData(searchFilterkey.includes(fieldName) ? 'Assign To' : sharedWithSearchKey.includes(fieldName) ? 'Shared With' : fieldName, inputValuesData[fieldName])
        }
        if (!filterData.length) {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
        } else {
            setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
        }
        setSearchResults({ ...searchResults, [fieldName]: filterData });
    }

    // const handleReasonChange = async (e) => {
    //     const inputValuesData = { ...inputValues, [e.target.name]: e.target.value }
    //     setInputValues(inputValuesData);
    //     // setFilterSelected(true)

    //     const fieldName = e.target.name;
    //     if (inputValuesData[fieldName].length < 3) {
    //         setSearchResults({ ...searchResults, [fieldName]: [] });
    //         return false
    //     };

    //     let filterData = [];
    //     filterData = reasonsList.filter(r => r.value.toLowerCase().indexOf(inputValuesData[fieldName]) > -1)

    //     if (!filterData.length) {
    //         setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: 'No result found.' })
    //     } else {
    //         setInputValuesNotFound({ ...inputValuesNotFound, [fieldName]: '' })
    //     }
    //     setSearchResults({ ...searchResults, [fieldName]: filterData });
    // }
    const handleDateChange = (name, date) => {
        setFilterDate({ ...filterDate, [name]: date });
    }
    const handleMarkedDateChange = (name, date) => {
        setMarkedDate({ ...markedDate, [name]: date });
    }
    const handleReassignDateChange = (name, date) => {
        setReassignDate({ ...reassignDate, [name]: date });
    }

    const handleReengagedDateChange = (name, date) => {
        setReengagedDate({ ...reengagedDate, [name]: date });
    }


    const handleTimeChange = (name, time) => {
        setCreatedTime({ ...createdTime, [name]: time });
    }
    const handleUpdatedDateChange = (name, date) => {
        setUpdatedDate({ ...updatedDate, [name]: date });
    }
    const handleSVDateChange = (name, date) => {
        setSVDate({ ...SVDate, [name]: date });
    }
    const handleSVCompleteDateChange = (name, date) => {
        setSVCompleteDate({ ...SVCompleteDate, [name]: date });
    }

    const handleFollowupDateChange = (name, date) => {
        setFollowupDate({ ...followupDate, [name]: date });
    }
    const handleBookingDateChange = (name, date) => {
        setBookingDate({ ...bookingDate, [name]: date })
    }
    const handleEoiDateChange = (name, date) => {
        setEoiDate({ ...eoiDate, [name]: date })
    }

    // API CALLS
    const getFilterData = async (type, value) => {
        // NOTE TODO : Change this on backend as this format is not acceptable
        if (type == "Mobile No") type = "Mobile No.";

        const res = await getLeadsFilter({ filterType: type, value: value });
        if (res.status === 200) {
            return res.data.data
        }
        else {
            return []
        }
    }

    const getCompanyName = async (searchText) => {
        return getAssignedCPs(searchText)
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                value: company.name,
                                id: company.id
                            }
                        });
                    return companies;
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const getAllCitiesByName = async (searchText) => {
        const res = await getAllCities(searchText)

        if (res.data.status === 200) {
            const cities = res.data?.data.map((city) => { return { label: city.name, value: city.name } });
            return cities
        } else {
            return []
        }
    }

    const getCompanyConfigData = async () => {
        await getCompanyConfig().then(res => {
            if (res.status === 200) {
                let result = res.data.data.result;
                if (!isRolesModule) {
                    setProjects(result.Project);
                }
                setConfig({ ...config, ...result })
            }
        })
    }

    const getAgencyData = async () => {
        await getCompanyAgency().then(res => {
            if (res.status === 200) {
                const agencyData = res.data.data.map((agency) => { return { label: agency.name, value: agency.id } })
                setAgencyDetails(agencyData)
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        });
    }

    const getDefaultUser = async () => {
        const res = await getMyUsers();
        if (res.data.status === 200) {
            const defaultUser = res.data.data.map(u => ({ ...u, value: u.name, user_id: u.id }))
            setSearchResults((prevState) => ({
                ...prevState,
                'Assigned To': [...defaultUser],
                'Shared With': [...defaultUser],
                'Marked By': [...defaultUser],
                'Sourcing Manager': [...defaultUser]
            }));

            setSearchDefaultResults((prevState) => ({
                ...prevState,
                "Assigned To": [...defaultUser],
                "Assigned User": [...defaultUser],
                'Shared With': [...defaultUser],
                'Marked By': [...defaultUser],
                'Sourcing Manager': [...defaultUser]
            }))
            getBrokerCompany();
        }
    }

    const getDeadReasonsStatus = () => {
        getDeadReasons().then(res => {
            if (res.status === 200) {
                const reasonsData = res.data.data.map((data) => {
                    return {
                        label: data.reason,
                        value: data.uuid
                    }
                })
                setDeadLeadReasons(reasonsData);
                // setConfig({ ...config, Dead: reasonsData });
            }
        })
    }

    const getTeams = async () => {
        const res = await getAllTeams()
        if (res.data.status === 200) {
            setTeamList(res.data.data.map(t => ({ ...t, label: t.name, value: t.uuid })));
        }
    }
    const getBrokerCompany = async () => {
        await getAssignedCPs('')
            .then((res) => {
                if (res.data.status === 200) {
                    const companies = res.data?.data
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                value: company.name,
                                id: company.id
                            }
                        });
                    const list = res.data?.data
                        .filter((company) => company.name)
                        .map(company => {
                            return {
                                ...company,
                                label: company.name,
                                value: company.id
                            }
                        });
                    setBrokerCompanyList((prevState) =>
                        [...list]
                    )
                    setSearchResults((prevState) => ({
                        ...prevState,
                        'CP Firm': [...companies],
                    }))
                    // setSearchResults({
                    //     ...searchResults,
                    //     'CP Firm': [...companies]
                    // });

                    setSearchDefaultResults((prevState) => ({
                        ...prevState,
                        'CP Firm': [...companies],
                    }))
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    //  this get project will execute for new roles permissions
    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map(item => ({ label: item.name, value: item.id }))
            setProjects(mapped);
        }
    }

    useEffect(() => {
        if (searchText) {
            let filteredList = defaultStaticFilterList.filter(i => i.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            setDefaultFilterList(filteredList);
        } else {
            setDefaultFilterList(defaultStaticFilterList);
        }
    }, [searchText]);

    useEffect(() => {
        getCompanyConfigData();
        getAgencyData();
        getDeadReasonsStatus();
        getAllDataJobs();
        getDefaultUser();
        getTeams();
        // getBrokerCompany();
    }, [])

    useEffect(() => {
        if (isRolesModule) {
            getProjects();
        }
    }, [isRolesModule]);

    useEffect(() => {
        if (name?.length || intent?.length || mobile?.length || project?.length || source?.length || reengageSource.length || agency?.length || eoiCancel.length || bookingCancel.length || Object.keys(filterDate).length || Object.keys(markedDate).length || Object.keys(createdTime).length || Object.keys(updatedDate).length || Object.keys(SVDate).length || Object.keys(SVCompleteDate).length || Object.keys(followupDate).length || Object.keys(bookingDate).length || Object.keys(eoiDate).length || Object.keys(reassignDate).length || Object.keys(reengagedDate).length || assign?.length || markedBy?.length || sharedWith?.length || status?.length || csvVal?.length || svTypeValue?.length || reengagement?.length || svCompleted.length || revisit?.length || hideTeamLeads?.length || city?.length || reason.length || dead?.length || purpose?.length || propertyType?.length || facing?.length || funding?.length || requirementType?.length || possession?.length || employmentType?.length || Object.keys(budget).length || email || configuration || preferredLocation || customerAddress || Object.keys(size).length || altMobile || industry.length || notConnectedReasons.length || inProgressReasons.length || team.length || svCancelReason.length || referredBy || brokerCode || fbFormValue?.length) {
            setFilterSelected(true)
        } else {
            setFilterSelected(false)
        }
    }, [name, mobile, intent, project, source, reengageSource, filterDate, markedDate, reassignDate, createdTime, updatedDate, SVDate, revisit, SVCompleteDate, assign, markedBy, sharedWith, status, csvVal, reengagement, hideTeamLeads, city, agency, eoiCancel, bookingCancel, dead, purpose, possession, funding, facing, employmentType, propertyType, requirementType, budget, followupDate, bookingDate, eoiDate, email, configuration, preferredLocation, customerAddress, size, altMobile, industry, referredBy, brokerCode, svTypeValue, notConnectedReasons, inProgressReasons, team, svCancelReason, reason, reengagedDate, fbFormValue, svCompleted])

    const sendFilterRequest = () => {
        let filterArr = [];
        if (name.length > 0) {
            filterArr.push({
                Name: name
            })
        }

        if (mobile.length > 0) {
            filterArr.push({
                "Mobile No.": mobile
            })
        }

        if (city.length > 0) {
            filterArr.push({
                City: city
            })
        }

        if (reason.length > 0) {
            filterArr.push({
                Reason: reason
            })
        }


        if (filterDate.startDate) {
            let endDate = filterDate.endDate || filterDate.startDate;
            filterArr.push({
                "Created Date": {
                    "From": filterDate.startDate,
                    "To": endDate
                }
            })
        }
        if (markedDate.startDate) {
            let endDate = markedDate.endDate || markedDate.startDate;
            filterArr.push({
                "status_changed_on": {
                    "From": markedDate.startDate,
                    "To": endDate
                }
            })
        }

        if (reassignDate.startDate) {
            let endDate = reassignDate.endDate || reassignDate.startDate;
            filterArr.push({
                "re_assign_date": {
                    "From": reassignDate.startDate,
                    "To": endDate
                }
            })
        }

        if (reengagedDate.startDate) {
            let endDate = reengagedDate.endDate || reengagedDate.startDate;
            filterArr.push({
                "re_engage_date": {
                    "From": reengagedDate.startDate,
                    "To": endDate
                }
            })
        }

        if (createdTime.startTime) {
            let endTime = createdTime.endTime || createdTime.startTime;
            filterArr.push({
                "Created Time": {
                    "From": createdTime.startTime,
                    "To": endTime
                }
            })
        }

        if (updatedDate.startDate) {
            let endDate = updatedDate.endDate || updatedDate.startDate;

            filterArr.push({
                "Updated Date": {
                    "From": updatedDate.startDate,
                    "To": endDate
                }
            })
        }
        if (SVDate.startDate) {
            let endDate = SVDate.endDate || SVDate.startDate;
            filterArr.push({
                "sv_schedule_date": {
                    "From": SVDate.startDate,
                    "To": endDate
                }
            })
        }
        if (SVCompleteDate.startDate) {
            let endDate = SVCompleteDate.endDate || SVCompleteDate.startDate;
            filterArr.push({
                "sv_complete_date": {
                    "From": SVCompleteDate.startDate,
                    "To": endDate
                }
            })
        }

        if (followupDate.startDate) {
            let endDate = followupDate.endDate || followupDate.startDate;
            filterArr.push({
                "followup_date": {
                    "From": followupDate.startDate,
                    "To": endDate
                }
            })
        }
        if (bookingDate.startDate) {
            let endDate = bookingDate.endDate || bookingDate.startDate;
            filterArr.push({
                "booking_date": {
                    "From": bookingDate.startDate,
                    "To": endDate
                }
            })
        }
        if (eoiDate.startDate) {
            let endDate = eoiDate.endDate || eoiDate.startDate;
            filterArr.push({
                "eoi_date": {
                    "From": eoiDate.startDate,
                    "To": endDate
                }
            })
        }


        if (intent.length > 0) {
            filterArr.push({
                Intent: intent.map((data) => {
                    return data.value
                })
            })
        }

        if (industry.length > 0) {
            filterArr.push({
                industry: industry.map((data) => {
                    return data.value
                })
            })
        }

        if (notConnectedReasons.length > 0) {
            filterArr.push({
                not_connected_reason: notConnectedReasons.map((data) => {
                    return data.value
                })
            })
        }
        if (inProgressReasons.length > 0) {
            filterArr.push({
                in_progress_reason: inProgressReasons.map((data) => {
                    return data.value
                })
            })
        }

        if (team.length > 0) {
            filterArr.push({
                teams: team.map(data => data.value)
            })
        }
        if (svCancelReason.length > 0) {
            filterArr.push({
                sv_cancellation_reason: svCancelReason.map((data) => {
                    return data.value
                })
            })
        }

        if (status.length > 0) {
            filterArr.push({
                Status: status.map((data) => {
                    return data.value
                })
            })
        }

        if (csvVal.length > 0) {
            filterArr.push({
                import_job_id: csvVal.map((data) => {
                    return data.value
                })
            })
        }

        if (svTypeValue.length > 0) {
            filterArr.push({
                sv_type: svTypeValue.map((data) => {
                    return data.value
                })
            })
        }
        if (reengagement.length > 0) {
            filterArr.push({
                is_re_engagement: true
            })
        }
        if (svCompleted.length > 0) {
            filterArr.push({
                is_site_visit_done: true
            })
        }
        if (revisit.length > 0) {
            filterArr.push({
                revisit: true
            })
        }
        if (hideTeamLeads.length > 0) {
            filterArr.push({
                hide_team_leads: true
            })
        }

        if (project.length > 0) {
            filterArr.push({
                Project: project.map((data) => {
                    return data.value
                })
            })
        }

        if (source.length > 0) {
            filterArr.push({
                Source: source.map((data) => {
                    return data.value
                })

            })
        }

        if (reengageSource.length > 0) {
            filterArr.push({
                re_engage_sources: reengageSource.map((data) => {
                    return data.value
                })

            })
        }
        if (fbFormValue.length > 0) {
            filterArr.push({
                'Form Id': fbFormValue.map((data) => {
                    return data.value
                })

            })
        }
        if (dead.length > 0) {
            filterArr.push({
                ['Dead Reasons']: dead.map((data) => {
                    return data.value
                })

            })
        }
        if (agency.length > 0) {
            filterArr.push({
                'Marketing Agency': agency.map((data) => {
                    return data.value
                })

            })
        }
        if (eoiCancel.length > 0) {
            filterArr.push({
                'eoi_cancellation_reason': eoiCancel.map((data) => {
                    return data.value
                })

            })
        }

        if (bookingCancel.length > 0) {
            filterArr.push({
                'booking_cancellation_reason': bookingCancel.map((data) => {
                    return data.value
                })

            })
        }
        if (assign.length > 0) {
            filterArr.push({
                "Assigned To": assign
            })
        }
        if (selectedRM.length > 0) {
            filterArr.push({
                "RMs": assign
            })
        }
        if (brokerCompany.length > 0) {
            filterArr.push({
                'broker_company_id': brokerCompany
            })
        }
        if (markedBy.length > 0) {
            filterArr.push({
                "status_changed_by": markedBy
            })
        }
        if (sharedWith.length > 0) {
            filterArr.push({
                "shared_with": sharedWith
            })
        }


        if (purpose.length > 0) {
            filterArr.push({
                'purpose': purpose.map(data => data.value)
            })
        }

        if (funding.length > 0) {
            filterArr.push({
                'funding_source': funding.map(data => data.value)
            })
        }
        if (facing.length > 0) {
            filterArr.push({
                'facing': facing.map(data => data.value)
            })
        }
        if (possession.length > 0) {
            filterArr.push({
                'possession': possession.map(data => data.value)
            })
        }
        if (requirementType.length > 0) {
            filterArr.push({
                'property_type': requirementType.map(data => data.value)
            })
        }
        if (employmentType.length > 0) {
            filterArr.push({
                'employment_type': employmentType.map(data => data.value)
            })
        }

        if (email) {
            if (!ALL_REGEX.EMAIL.test(email)) {
                toast.error('Please enter a valid email.')
                return;
            }
            filterArr.push({
                'email': email
            })
        }

        if (altMobile) {
            filterArr.push({
                'alternate_mobile': altMobile
            })
        }
        if (customerAddress) {
            filterArr.push({
                'customer_address': customerAddress
            })
        }

        if (preferredLocation) {
            filterArr.push({
                'preferred_location': preferredLocation
            })
        }

        if (referredBy) {
            filterArr.push({
                'referred_by': referredBy
            })
        }
        if (brokerCode) {
            filterArr.push({
                'broker_user_code': brokerCode
            })
        }
        if (configuration) {
            filterArr.push({
                'configuration': configuration
            })
        }
        if (propertyType.length > 0) {
            filterArr.push({
                'property_category': propertyType.map(data => data.value)
            })
        }

        if (Object.keys(budget).length > 0) {
            filterArr.push({
                'budget': budget
            })
        }
        if (Object.keys(size).length > 0) {

            if ((!Object.keys(size).includes('min') || !size.min) &&
                (!Object.keys(size).includes('max') || !size.max)) {
                toast.error('Please specify size');
                return;
            }

            if (!Object.keys(size).includes('unit')) {
                toast.error('Please select size unit');
                return;
            }

            filterArr.push({
                'size': size
            })
        }

        setFilterCount(filterArr.length)
        handleFilter(filterArr);
        localStorage.setItem('filter', JSON.stringify(filterArr))
        localStorage.removeItem('currentPage');
        filterToaster();
        setCurrentPage(1)
        setShow(false)

    }

    const handelRemoveClick = () => {
        if (filterSelected || intent.length > 0 || status.length > 0 || csvVal.length > 0 || reengageSource.length > 0 || svTypeValue.length > 0 || reengagement.length > 0 || svCompleted.length > 0 || revisit.length > 0 || hideTeamLeads.length > 0 || project.length > 0 || agency.length > 0 || eoiCancel.length > 0 || bookingCancel.length > 0 || (filterDate.startDate) || markedDate.startDate || reassignDate.startDate || reengagedDate.startDate || createdTime.startTime || filtersData?.length || industry.length > 0 || notConnectedReasons.length > 0 || inProgressReasons.length > 0 || team.length > 0 || svCancelReason.length > 0 || fbFormValue.length > 0) {
            toast.success("All filters Removed");
            setInputValues({
                "Name": "",
                "Mobile No": "",
                "Project": "",
                "Source": "",
                "Assigned To": "",
                City: '',
                'Shared With': '',
                Reasons: '',
                'Sourcing Manager': '',
                'CP Firm': ''
            })
            localStorage.removeItem('filter')
            setFilterCount(0);
            handleFilter([]);
            setCurrentPage(1)
            setFilterDate({})
            setMarkedDate({})
            setReassignDate({})
            setReengagedDate({})
            setCreatedTime({})
            setUpdatedDate({})
            setSVDate({})
            setSVCompleteDate({})
            setFollowupDate({})
            setBookingDate({})
            setEoiDate({})
            setAgency([])
            // setFilterSelected(false);
            setname([])
            setCity([])
            setSharedWith([])
            setMarkedBy([])
            setReason([])
            setmobile([])
            setintent([])
            setIndustry([])
            setproject([])
            setstatus([])
            setCSVVal([])
            setSVTypeValue([])
            setSource([])
            setReengageSource([])
            setFbFormValue([])
            setDead([])
            setPurpose([])
            setFacing([])
            setFunding([])
            setBudget({})
            setPropertyType([])
            setRequirementType([])
            setEmploymentType([])
            setPossession([]);
            setReengagement([]);
            setSvCompleted([])
            setRevisit([]);
            setHideTeamLeads([]);
            // setFilterDate([])
            setAssign([])
            setEoiCancel([]);
            setBookingCancel([])
            setMarkedBy([])
            setEmail('')
            setAltMobile('')
            setSize({})
            setConfigurtion('')
            setPreferredLocation('')
            setReferredBy('')
            setBrokerCode('')
            setCustomerAddress('')
            setSearchResults({
                "Name": [],
                "Mobile No": [],
                "Project": [],
                "Source": [],
                "Assigned To": [],
                City: [],
                'Shared With': [],
                'Marked By': [],
                Reasons: [],
                'Sourcing Manager': [],
                'CP Firm': []
            })
            setNotConnectedReasons([])
            setInProgressReasons([]);
            setTeam([]);
            setSvCancelReason([])
        }
    }

    /***
     * update filter value after refresh if filter applied
     */
    const updateFilterValaue = () => {
        let filters = filtersData;
        let filtersArr = {};
        for (const filterData of filters) {
            filtersArr = { ...filtersArr, ...filterData }
        }

        let filterKeys = Object.keys(filtersArr);

        if (filterKeys.includes("Name")) {
            setname(filtersArr.Name || [])
        }

        if (filterKeys.includes('City')) {
            setCity(filtersArr.City || [])
        }

        if (filterKeys.includes('Reason')) {
            setReason(filtersArr.Reason || [])
        }
        if (filterKeys.includes("Mobile No.")) {
            setmobile(filtersArr['Mobile No.'] || [])
        }

        if (filterKeys.includes("Created Date")) {
            const { From, To } = filtersArr['Created Date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setFilterDate(date || {})
        }


        if (filterKeys.includes("status_changed_on")) {
            const { From, To } = filtersArr['status_changed_on']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setMarkedDate(date || {})
        }

        if (filterKeys.includes("re_assign_date")) {
            const { From, To } = filtersArr['re_assign_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setReassignDate(date || {})
        }

        if (filterKeys.includes("re_engage_date")) {
            const { From, To } = filtersArr['re_engage_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setReengagedDate(date || {})
        }

        if (filterKeys.includes("Created Time")) {
            const { From, To } = filtersArr['Created Time']
            const date = {
                'startTime': From,
                'endTime': To,
            }
            setCreatedTime(date || {})
        }

        if (filterKeys.includes("Updated Date")) {
            const { From, To } = filtersArr['Updated Date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setUpdatedDate(date || {})
        }
        if (filterKeys.includes("sv_schedule_date")) {
            const { From, To } = filtersArr['sv_schedule_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setSVDate(date || {})
        }
        if (filterKeys.includes("sv_completed_date")) {
            const { From, To } = filtersArr['sv_completed_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setSVCompleteDate(date || {})
        }

        if (filterKeys.includes('followup_date')) {
            const { From, To } = filtersArr['followup_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setFollowupDate(date || {})
        }
        if (filterKeys.includes('booking_date')) {
            const { From, To } = filtersArr['booking_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setBookingDate(date || {})
        }
        if (filterKeys.includes('eoi_date')) {
            const { From, To } = filtersArr['eoi_date']
            const date = {
                'startDate': From,
                'endDate': To,
            }
            setEoiDate(date || {})
        }
        if (filterKeys.includes("Intent")) {
            const filteredintent = filtersArr.Intent.map(int => {
                return config["Intent"]?.find(conf => conf.value === int)
            })
            setintent(filteredintent || [])
        }

        if (filterKeys.includes("industry")) {
            const filteredIndustry = filtersArr.industry.map(indus => {
                return propertyEnums.enum_industry?.find(ind => ind.value === indus)
            })
            setIndustry(filteredIndustry || [])
        }
        if (filterKeys.includes("not_connected_reason")) {
            const filtered = filtersArr.not_connected_reason.map(indus => {
                return NotConnectedReasonsList?.find(ind => ind.value === indus)
            })
            setNotConnectedReasons(filtered || [])
        }
        if (filterKeys.includes("in_progress_reason")) {
            const filtered = filtersArr.in_progress_reason.map(indus => {
                return InProgessReasonsList?.find(ind => ind.value === indus)
            })
            setInProgressReasons(filtered || [])
        }
        if (filterKeys.includes("teams")) {
            const filtered = filtersArr.teams.map(t => {
                return teamList?.find(team => team.value === t)
            })
            setTeam(filtered || [])
        }
        if (filterKeys.includes("sv_cancellation_reason")) {
            const filtered = filtersArr.sv_cancellation_reason.map(indus => {
                return SVCancelledReasonList?.find(ind => ind.value === indus)
            })
            setSvCancelReason(filtered || [])
        }

        if (filterKeys.includes("Status")) {
            const filteredStatus = filtersArr.Status.map(int => {
                return config["Status"]?.find(conf => conf.value === int)
            })
            setstatus(filteredStatus || [])
        }
        if (filterKeys.includes("import_job_id")) {
            const filteredStatus = filtersArr.import_job_id.map(int => {
                return csvList?.find(conf => conf.value === int)
            })
            setCSVVal(filteredStatus || [])
        }
        if (filterKeys.includes("sv_type")) {
            const filteredStatus = filtersArr.sv_type.map(int => {
                return svTypes?.find(conf => conf.value === int)
            })
            setSVTypeValue(filteredStatus || [])
        }
        if (filterKeys.includes("Status")) {
            const filteredStatus = filtersArr.Status.map(int => {
                return config["Status"]?.find(conf => conf.value === int)
            })
            setstatus(filteredStatus || [])
        }
        if (filterKeys.includes("is_re_engagement")) {
            const filteredStatus = [
                {
                    label: 'Show Re-Engaged Leads',
                    value: 'reengage'
                }
            ]
            setReengagement(filteredStatus || [])
        }
        if (filterKeys.includes("is_site_visit_done")) {
            const filteredStatus = [
                {
                    label: 'Show Site Visit Completed Leads',
                    value: 'completed'
                }
            ]
            setSvCompleted(filteredStatus || [])
        }

        if (filterKeys.includes("revisit")) {
            const filteredStatus = [
                {
                    label: 'Show Revisit',
                    value: 'revisit'
                }
            ]
            setRevisit(filteredStatus || [])
        }

        if (filterKeys.includes("hide_team_leads")) {
            const filteredStatus = [
                {
                    label: 'Hide Team Leads',
                    value: true
                }
            ]
            setHideTeamLeads(filteredStatus || [])
        }

        if (filterKeys.includes("Project")) {
            const filteredProject = filtersArr.Project.map(int => {
                return projects?.find(conf => conf.value === int)
            })
            setproject(filteredProject || [])
        }

        if (filterKeys.includes("Source")) {
            const filteredSource = filtersArr.Source.map(int => {
                return config["Source"]?.find(conf => conf.value === int)
            })
            setSource(filteredSource || [])
        }

        if (filterKeys.includes("re_engage_sources")) {
            const filteredSource = filtersArr.re_engage_sources.map(int => {
                return config["Source"]?.find(conf => conf.value === int)
            })
            setReengageSource(filteredSource || [])
        }

        if (filterKeys.includes("Form Id")) {
            const filteredSource = filtersArr['Form Id'].map(id => {
                return fbFormList?.find(form => form.value === id)
            })
            setFbFormValue(filteredSource || [])
        }

        if (filterKeys.includes('Marketing Agency')) {
            const filteredSource = filtersArr['Marketing Agency'].map(int => {
                return agencyDetails?.find(agency => agency.value === int)
            })
            setAgency(filteredSource || [])
        }
        if (filterKeys.includes('eoi_cancellation_reason')) {
            const filteredSource = filtersArr['eoi_cancellation_reason'].map(int => {
                return EOICancelReasonList?.find(item => item.value === int)
            })
            setEoiCancel(filteredSource || [])
        }
        if (filterKeys.includes('booking_cancellation_reason')) {
            const filteredSource = filtersArr['booking_cancellation_reason'].map(int => {
                return EOICancelReasonList?.find(item => item.value === int)
            })
            setBookingCancel(filteredSource || [])
        }

        if (filterKeys.includes("Dead Reasons")) {
            const filteredDead = filtersArr['Dead Reasons'].map(int => {
                return deadLeadReasons?.find(conf => conf.value === int)
            })

            setDead(filteredDead || [])
        }

        if (filterKeys.includes("Assigned To")) {
            setAssign(filtersArr['Assigned To'] || [])
        }
        if (filterKeys.includes("RMs")) {
            setSelectedRM(filtersArr['RMs'] || [])
        }

        if (filterKeys.includes("shared_with") && searchResults['Shared With']?.length > 0) {
            // const filetredData = filtersArr['shared_with'].map(int => {
            //     return searchResults["Shared With"].find(s => s.id === int)
            // })
            //     .map(u => ({ ...u, value: u.name, user_id: u.id }))
            // console.log(filetredData, 'filetredData filetredData')
            setSharedWith(filtersArr['shared_with'] || [])
        }


        if (filterKeys.includes("status_changed_by")) {
            setMarkedBy(filtersArr['status_changed_by'] || [])
        }

        if (filterKeys.includes("purpose")) {
            const filteredPurpose = filtersArr.purpose.map(int => {
                return propertyEnums.enum_purpose?.find(conf => conf.value === int)
            })
            setPurpose(filteredPurpose || [])
        }

        if (filterKeys.includes("property_category")) {
            const filteredValues = filtersArr.property_category.map(int => {
                return propertyEnums.enum_property_category?.find(conf => conf.value === int)
            })
            setPropertyType(filteredValues || [])
        }

        if (filterKeys.includes("property_type")) {
            const filteredValues = filtersArr.property_type.map(int => {
                return propertyEnums.enum_property_type?.find(conf => conf.value === int)
            })
            setRequirementType(filteredValues || [])
        }

        if (filterKeys.includes("funding_source")) {
            const filteredValues = filtersArr.funding_source.map(int => {
                return propertyEnums.enum_funding_source?.find(conf => conf.value === int)
            })
            setFunding(filteredValues || [])
        }

        if (filterKeys.includes("employment_type")) {
            const filteredValues = filtersArr.employment_type.map(int => {
                return employment_list?.find(conf => conf.value === int)
            })
            setEmploymentType(filteredValues || [])
        }

        if (filterKeys.includes("facing")) {
            const filteredValues = filtersArr.facing.map(int => {
                return propertyEnums.enum_facing?.find(conf => conf.value === int)
            })
            setFacing(filteredValues || [])
        }

        if (filterKeys.includes("possession")) {
            const filteredValues = filtersArr.possession.map(int => {
                return propertyEnums.enum_possession?.find(conf => conf.value === int)
            })
            setPossession(filteredValues || [])
        }

        if (filterKeys.includes('email')) {
            setEmail(filtersArr.email)
        }

        if (filterKeys.includes('alternate_mobile')) {
            setAltMobile(filtersArr.alternate_mobile)
        }

        if (filterKeys.includes('budget')) {
            setBudget(filtersArr.budget)
        }

        if (filterKeys.includes('configuration')) {
            setConfigurtion(filtersArr.configuration)
        }
        if (filterKeys.includes('customer_address')) {
            setCustomerAddress(filtersArr.customer_address)
        }
        if (filterKeys.includes('preferred_location')) {
            setPreferredLocation(filtersArr.preferred_location)
        }

        if (filterKeys.includes('referred_by')) {
            setReferredBy(filtersArr.referred_by)
        }
        if (filterKeys.includes('broker_user_code')) {
            setBrokerCode(filterKeys.broker_user_code)
        }
        if (filterKeys.includes('size')) {
            setSize(filtersArr.size)
        }
    }

    useEffect(() => {
        updateFilterValaue();
    }, [
        config,
        filtersData,
        propertyEnums,
        show,
        deadLeadReasons,
        agencyDetails,
        projects,
        employment_list,
        svTypes,
        csvList,
        SVCancelledReasonList,
        InProgessReasonsList,
        NotConnectedReasonsList
    ])

    useEffect(() => {
        let filters = filtersData;
        const filtersArr = [];
        for (const filterData of filters) {
            filtersArr.push(Object.keys(filterData)[0])
        }
        if (!filtersArr.includes("Name")) {
            setname([])
        }

        if (!filtersArr.includes('City')) {
            setCity([])
        }

        if (!filtersArr.includes('Reason')) {
            setReason([])
        }

        if (!filtersArr.includes("Mobile No.")) {
            setmobile([])
        }

        if (!filtersArr.includes("Created Date")) {

            setFilterDate({})
        }

        if (!filtersArr.includes("status_changed_on")) {
            setMarkedDate({})
        }
        if (!filtersArr.includes('re_assign_date')) {
            setReassignDate({})
        }
        if (!filtersArr.includes('re_engage_date')) {
            setReengagedDate({})
        }

        if (!filtersArr.includes("Created Time")) {
            setCreatedTime({})
        }
        if (!filtersArr.includes("Updated Date")) {
            setUpdatedDate({})
        }
        if (!filtersArr.includes("SV Date")) {
            setSVDate({})
        }
        if (!filtersArr.includes("sv_completed_date")) {
            setSVCompleteDate({})
        }

        if (!filtersArr.includes('followup_date')) {
            setFollowupDate({})
        }
        if (!filtersArr.includes('booking_date')) {
            setBookingDate({})
        }
        if (!filtersArr.includes('eoi_date')) {
            setEoiDate({})
        }
        if (!filtersArr.includes("Intent")) {
            setintent([])
        }
        if (!filtersArr.includes("industry")) {
            setIndustry([])
        }

        if (!filtersArr.includes("not_connected_reason")) {
            setNotConnectedReasons([])
        }
        if (!filtersArr.includes("in_progress_reason")) {
            setInProgressReasons([])
        }
        if (!filtersArr.includes("team")) {
            setTeam([])
        }
        if (!filtersArr.includes("sv_cancellation_reason")) {
            setSvCancelReason([])
        }

        if (!filtersArr.includes("Marketing Agency")) {
            setAgency([])
        }

        if (!filtersArr.includes("eoi_cancellation_reason")) {
            setEoiCancel([])
        }
        if (!filtersArr.includes("booking_cancellation_reason")) {
            setBookingCancel([])
        }

        if (!filtersArr.includes("Status")) {
            setstatus([])
        }
        if (!filtersArr.includes("import_job_id")) {
            setCSVVal([])
        }
        if (!filtersArr.includes("sv_type")) {
            setSVTypeValue([])
        }
        if (!filtersArr.includes("is_re_engagement")) {
            setReengagement([])
        }
        if (!filtersArr.includes("is_site_visit_done")) {
            setSvCompleted([])
        }
        if (!filtersArr.includes("re_visit")) {
            setRevisit([])
        }

        if (!filtersArr.includes("hide_team_leads")) {
            setHideTeamLeads([])
        }

        if (!filtersArr.includes("Project")) {
            setproject([])
        }

        if (!filtersArr.includes("Source")) {
            setSource([])
        }

        if (!filtersArr.includes("re_engage_sources")) {
            setReengageSource([])
        }
        if (!filtersArr.includes("Form Id")) {
            setFbFormValue([])
        }

        if (!filtersArr.includes("Dead Reasons")) {
            setDead([])
        }

        if (!filtersArr.includes("Assigned To")) {
            setAssign([])
        }
        if (!filtersArr.includes("selected_RM")) {
            setSelectedRM([])
        }
        if (!filtersArr.includes('status_changed_by')) {
            setMarkedBy([]);
        }

        if (!filtersArr.includes("purpose")) {
            setPurpose([])
        }
        if (!filtersArr.includes("possession")) {
            setPossession([])
        }

        if (!filtersArr.includes('budget')) {
            setBudget({})
        }

        if (!filtersArr.includes("property_category")) {
            setRequirementType([])
        }
        if (!filtersArr.includes("employment_type")) {
            setEmploymentType([])
        }

        if (!filtersArr.includes("property_type")) {
            setPropertyType([])
        }
        if (!filtersArr.includes("facing")) {
            setFacing([])
        }
        if (!filtersArr.includes("funding_source")) {
            setFunding([])
        }

        if (!filtersArr.includes("email")) {
            setEmail('')
        }

        if (!filtersArr.includes('alternate_mobile')) {
            setAltMobile('')
        }
        if (!filtersArr.includes("customer_address")) {
            setCustomerAddress('')
        }
        if (!filtersArr.includes("preferred_location")) {
            setPreferredLocation('')
        }

        if (!filtersArr.includes('referred_by')) {
            setReferredBy('')
        }

        if (!filtersArr.includes("configuration")) {
            setConfigurtion('')
        }
        if (!filtersArr.includes("size")) {
            setSize({});
        }

        setFilterCount(filtersArr.length);
    }, [filtersData])


    const handleAssign = useMemo(() => {
        const index = searchResults['Assigned To'].findIndex(result => result.value === 'Unassigned')
        const assignIndex = assign.findIndex(result => result.value === 'Unassigned')
        if (assignIndex >= 0) {
            return searchResults["Assigned To"]
        }

        if (index === -1) {
            return [{ value: 'Unassigned', id: null }, ...searchResults["Assigned To"]]
        }

        return searchResults["Assigned To"]
    }, [searchResults["Assigned To"], assign])

    // const handleSharedWith = useMemo(() => {
    //     const index = searchResults['Assigned To'].findIndex(result => result.value === 'Unassigned')
    //     const assignIndex = assign.findIndex(result => result.value === 'Unassigned')
    //     if (assignIndex >= 0) {
    //         return searchResults["Assigned To"]
    //     }

    //     if (index === -1) {
    //         return [{ value: 'Unassigned', id: null }, ...searchResults["Assigned To"]]
    //     }

    //     return searchResults["Assigned To"]
    // }, [searchResults["Assigned To"], assign])

    return (
        <SliderModal
            show={show}
            setShow={setShow}
            title={'Filters'}
            titleRight={<>
                {filterSelected || filtersData.length > 0 ?
                    <a className="fz14 h-font mr-24 black-dark-700 fw-po-medium pr-hover" onClick={handelRemoveClick}>Remove all</a>
                    : " "}
            </>
            }
            body={
                <>
                    <div className="filter">
                        <div className='w-full'>
                            <SearchInput
                                placeholder={'Search Filters'}
                                searchText={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                handelClear={() => setSearchText('')}

                            /></div>
                        <div className="all-fliters">
                            <div id="accordion">
                                {defaultFilterList.includes('Project') && projects.length > 0 && <CheckAccordion
                                    value={project}
                                    setValue={setproject}
                                    selectionValues={projects}
                                    accordionName="Project"
                                    label="Project"
                                />}
                                {defaultFilterList.includes('Status') && (leadType === leadTypesEnum.BOOKINGS ? "" :
                                    <CheckAccordion
                                        value={status}
                                        setValue={setstatus}
                                        selectionValues={(allowedPermissions?.includes('hide_dead_leads')) ? config["Status"].filter(item => item.label !== 'Dead Lead') : config["Status"]}
                                        accordionName="Status"
                                        label="Status"
                                    />
                                )}
                                {defaultFilterList.includes('Intent') &&
                                    <CheckAccordion
                                        value={intent}
                                        setValue={setintent}
                                        selectionValues={[...config["Intent"]]}
                                        accordionName="Intent"
                                        label="Intent"
                                        showSearch={false}
                                    />}
                                {(defaultFilterList.includes('Site Visit Date') && userProfile?.allowed_modules?.includes('new_booking')) && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#svDateCollapse" aria-expanded="false" aria-controls="svDateCollapse" id="svDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                SV Schedule Date
                                                {Object.keys(SVDate).length > 0 && <span>{Object.keys(SVDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="svDateCollapse" className="filter-body collapse" aria-labelledby="svDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={SVDate.startDate ? new Date(SVDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleSVDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={SVDate.endDate ? new Date(SVDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleSVDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {(defaultFilterList.includes('Site Visit Completed Date') && userProfile?.allowed_modules?.includes('new_booking')) && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#svCompleteDateCollapse" aria-expanded="false" aria-controls="svCompleteDateCollapse" id="svCompleteDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                SV Complete Date
                                                {Object.keys(SVCompleteDate).length > 0 && <span>{Object.keys(SVCompleteDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="svCompleteDateCollapse" className="filter-body collapse" aria-labelledby="svCompleteDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={SVCompleteDate.startDate ? new Date(SVCompleteDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleSVCompleteDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={SVCompleteDate.endDate ? new Date(SVCompleteDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleSVCompleteDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Created Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedate" aria-expanded="false" aria-controls="collapsedate" id="headingDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Created Date
                                                {Object.keys(filterDate).length > 0 && <span>{Object.keys(filterDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsedate" className="filter-body collapse" aria-labelledby="headingDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={filterDate.startDate ? new Date(filterDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={filterDate.endDate ? new Date(filterDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={filterDate.startDate}
                                                    onChange={(date) => handleDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Created Time') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsedatetime" aria-expanded="false" aria-controls="collapsedatetime" id="headingDateTime">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Created Date & Time
                                                {Object.keys(createdTime).length > 0 && <span>{Object.keys(createdTime).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsedatetime" className="filter-body collapse" aria-labelledby="headingDateTime" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Time
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdTime.startTime ? new Date(createdTime.startTime) : ''}
                                                    placeholderText={'Start Time'}
                                                    onChange={(date) => handleTimeChange('startTime', date)}
                                                    className={`form-control text-input`}
                                                    showTimeSelect
                                                    timeIntervals={15}

                                                    timeCaption="Time"
                                                    dateFormat="dd MMM yy, h:mm aa"
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Time
                                                </Form.Label>
                                                <DatePicker
                                                    selected={createdTime.endTime ? new Date(createdTime.endTime) : ''}
                                                    placeholderText={'End Time'}
                                                    className={`form-control text-input`}
                                                    onChange={(date) => handleTimeChange('endTime', date)}
                                                    minDate={createdTime.startDate}
                                                    showTimeSelect
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    dateFormat="dd MMM yy, h:mm aa"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {(defaultFilterList.includes('Followup Date') && userProfile?.allowed_modules?.includes('new_booking')) && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#followupDateCollapse" aria-expanded="false" aria-controls="followupDateCollapse" id="fwDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Followup Date
                                                {Object.keys(followupDate).length > 0 && <span>{Object.keys(followupDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="followupDateCollapse" className="filter-body collapse" aria-labelledby="fwDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={followupDate.startDate ? new Date(followupDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleFollowupDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={followupDate.endDate ? new Date(followupDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleFollowupDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('filter_assigned_to')) || ROLE_NAME === 'ADMIN') &&
                                    defaultFilterList.includes('Assigned User') &&
                                    <DynamicAccordion
                                        accordionName="Assigned User"
                                        placeholder='Enter user name to search'
                                        value={assign}
                                        setValue={setAssign}
                                        searchResults={handleAssign}
                                        setSearchResults={(data) => setSearchResults({ ...searchResults, ["Assigned To"]: structuredClone(data) })}
                                        inputValues={inputValues["Assigned To"]}
                                        handleInputChange={handleInputChange}
                                        notFound={inputValuesNotFound["Assigned To"]}
                                        setInputValues={setInputValues}
                                        inputValuesNotFound={inputValuesNotFound}
                                        setInputValuesNotFound={setInputValuesNotFound}
                                        searchDefultResults={searchDefultResults}
                                    />
                                }

                                {defaultFilterList.includes('Source') &&
                                    <CheckAccordion
                                        value={source}
                                        setValue={setSource}
                                        selectionValues={config["Source"]}
                                        accordionName="Source"
                                        label='Source'
                                    />}
                                {defaultFilterList.includes('Budget') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#budget" aria-expanded="false" aria-controls="budget" id="headingDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Budget
                                                {Object.keys(budget).length > 0 && <span>{Object.keys(budget).length}</span>}
                                            </h2>

                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="budget" className="filter-body collapse" aria-labelledby="headingDate" data-parent="#accordion">
                                        <div className={`${Object.keys(budget).length > 0 ? "" : 'd-none'} px-16`}>
                                            <button className={`border-0 bg-tr clear-btn px-0 `} onClick={() => {
                                                setBudget({})
                                            }}
                                            >
                                                Clear All
                                            </button>
                                        </div>
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Minimum
                                                </Form.Label>
                                                <Select
                                                    maxMenuHeight={'150px'}
                                                    options={budgetRange}
                                                    value={budget.min || ''}
                                                    onChange={value => { setBudget({ ...budget, min: value }) }}
                                                    placeholder="Min. Budget"
                                                    displayValue="label"
                                                // singleSelect={true}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    Maximum
                                                </Form.Label>
                                                <Select
                                                    maxMenuHeight={'150px'}
                                                    options={budgetRange.filter(range => range.value > (budget?.min?.value || 0))}
                                                    value={budget.max || ''}
                                                    onChange={value => { setBudget({ ...budget, max: value }) }}
                                                    placeholder="Max. Budget"
                                                    displayValue="label"
                                                // singleSelect={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Shared With') && <DynamicAccordion
                                    accordionName="Shared With"
                                    placeholder='Enter user name to search'
                                    value={sharedWith}
                                    setValue={setSharedWith}
                                    searchResults={structuredClone(searchResults['Shared With'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, 'Shared With': structuredClone(data) })}
                                    inputValues={inputValues["Shared With"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["Shared With"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />}
                                {!(allowedPermissions?.includes('hide_dead_leads')) && defaultFilterList.includes('Dead Reasons') &&
                                    <CheckAccordion
                                        value={dead}
                                        setValue={setDead}
                                        selectionValues={deadLeadReasons}
                                        accordionName="Dead-lead"
                                        label='Dead Reasons'
                                    />}
                                {defaultFilterList.includes('Re-Engaged Leads') && <CheckAccordion
                                    value={reengagement}
                                    setValue={setReengagement}
                                    selectionValues={[{
                                        label: 'Show Re-Engaged Leads',
                                        value: 'reengage'
                                    }]}
                                    accordionName="re-engaged"
                                    label="Re-Engaged Leads"
                                    showSearch={false}
                                />}
                                {defaultFilterList.includes('Site Visit Completed') && <CheckAccordion
                                    value={svCompleted}
                                    setValue={setSvCompleted}
                                    selectionValues={[{
                                        label: 'Show Site Visit Completed Leads',
                                        value: 'completed'
                                    }]}
                                    accordionName="site_visit_completed"
                                    label="Site Visit Completed Leads"
                                    showSearch={false}
                                />}

                                {defaultFilterList.includes('Re-Engaged Source') &&
                                    <CheckAccordion
                                        value={reengageSource}
                                        setValue={setReengageSource}
                                        selectionValues={structuredClone(config["Source"])}
                                        accordionName="Reengage_Source"
                                        label='Re-Engaged Source'
                                    />}
                                {defaultFilterList.includes('Re-Engaged Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapseengageddate" aria-expanded="false" aria-controls="collapseengageddate" id="headingReengagedDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Re-engage Date
                                                {Object.keys(reengagedDate).length > 0 && <span>{Object.keys(reengagedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapseengageddate" className="filter-body collapse" aria-labelledby="headingReengagedDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reengagedDate.startDate ? new Date(reengagedDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleReengagedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reengagedDate.endDate ? new Date(reengagedDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={reengagedDate.startDate}
                                                    onChange={(date) => handleReengagedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Preferred Location') && <StaticFieldAccordian
                                    accordionName={"Preferred_Location"}
                                    label={'Preferred Location'}
                                    value={preferredLocation}
                                    placeholder='Search by location'
                                    setValue={setPreferredLocation}
                                    defaultExapanded={false}
                                />}
                                {defaultFilterList.includes('SV Type') && <CheckAccordion
                                    value={svTypeValue}
                                    setValue={setSVTypeValue}
                                    selectionValues={svTypes}
                                    accordionName="sv_types"
                                    label="SV Type"
                                />}
                                {defaultFilterList.includes('Assigned Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#assignedDateCollapse" aria-expanded="false" aria-controls="assignedDateCollapse" id="assignedDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Assigned Date
                                                {Object.keys(updatedDate).length > 0 && <span>{Object.keys(updatedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="assignedDateCollapse" className="filter-body collapse" aria-labelledby="assignedDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={updatedDate.startDate ? new Date(updatedDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleUpdatedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={updatedDate.endDate ? new Date(updatedDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleUpdatedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {(defaultFilterList.includes('Booking Date') && userProfile?.allowed_modules?.includes('new_booking')) && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#bookingDateCollapse" aria-expanded="false" aria-controls="bookingDateCollapse" id="bkDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Booking Date
                                                {Object.keys(bookingDate).length > 0 && <span>{Object.keys(bookingDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="bookingDateCollapse" className="filter-body collapse" aria-labelledby="bkDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={bookingDate.startDate ? new Date(bookingDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleBookingDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={bookingDate.endDate ? new Date(bookingDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleBookingDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {((defaultFilterList.includes('Token Date') || defaultFilterList.includes('Eoi Date')) && userProfile?.allowed_modules?.includes('new_booking')) && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#eoiDateCollapse" aria-expanded="false" aria-controls="eoiDateCollapse" id="eiDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                {userProfile?.company_id === 581 ? 'Token Date' : 'Eoi Date'}
                                                {Object.keys(eoiDate).length > 0 && <span>{Object.keys(eoiDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="eoiDateCollapse" className="filter-body collapse" aria-labelledby="eiDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={eoiDate.startDate ? new Date(eoiDate.startDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleEoiDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={eoiDate.endDate ? new Date(eoiDate.endDate) : ''}
                                                    placeholderText={'Select Date'}
                                                    // maxDate={new Date()}
                                                    onChange={(date) => handleEoiDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Size') && <SizeFilterAccordian
                                    accordionName={"Size"}
                                    label={'Preferred Size'}
                                    sizeVal={size}
                                    placeholder=''
                                    setValue={setSize}
                                    defaultExapanded={false}
                                    options={OPTIONS}
                                />}
                                {defaultFilterList.includes('Not Connected Reasons') &&
                                    <CheckAccordion
                                        value={notConnectedReasons}
                                        setValue={setNotConnectedReasons}
                                        selectionValues={NotConnectedReasonsList}
                                        accordionName="not_connected_reasons"
                                        label="Not Connected Reasons"
                                    />}
                                {defaultFilterList.includes('SV Cancel Reasons') &&
                                    <CheckAccordion
                                        value={svCancelReason}
                                        setValue={setSvCancelReason}
                                        selectionValues={SVCancelledReasonList}
                                        accordionName="sv_cancelled_reason"
                                        label="SV Cancel Reasons"
                                    />}
                                {defaultFilterList.includes('In-Progress Reasons') &&
                                    <CheckAccordion
                                        value={inProgressReasons}
                                        setValue={setInProgressReasons}
                                        selectionValues={InProgessReasonsList}
                                        accordionName="in_progress_reasons"
                                        label="In-Progress Reasons"
                                        showSearch={false}
                                    />}
                                {((ROLE_NAME === 'Super Admin' || allowedPermissions?.includes('filter_assigned_to')) || ROLE_NAME === 'ADMIN') &&
                                    defaultFilterList.includes('Marked By') &&
                                    <DynamicAccordion
                                        accordionName="Marked By"
                                        placeholder='Enter user name to search'
                                        value={markedBy}
                                        setValue={setMarkedBy}
                                        searchResults={structuredClone(searchResults['Marked By'])}
                                        setSearchResults={(data) => setSearchResults({ ...searchResults, ["Marked By"]: structuredClone(data) })}
                                        inputValues={inputValues["Marked By"]}
                                        handleInputChange={handleInputChange}
                                        notFound={inputValuesNotFound["Marked By"]}
                                        setInputValues={setInputValues}
                                        inputValuesNotFound={inputValuesNotFound}
                                        setInputValuesNotFound={setInputValuesNotFound}
                                        searchDefultResults={searchDefultResults}
                                    />
                                }

                                {defaultFilterList.includes('Marked Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#markeddate" aria-expanded="false" aria-controls="markeddate" id="headingMarked">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Marked Date
                                                {Object.keys(markedDate).length > 0 && <span>{Object.keys(markedDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="markeddate" className="filter-body collapse" aria-labelledby="headingMarked" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={markedDate.startDate ? new Date(markedDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleMarkedDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={markedDate.endDate ? new Date(markedDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={markedDate.startDate}
                                                    onChange={(date) => handleMarkedDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('Funding Source') &&
                                    <CheckAccordion
                                        value={funding}
                                        setValue={setFunding}
                                        selectionValues={propertyEnums.enum_funding_source}
                                        accordionName="Funding_Source"
                                        label='Funding Source'
                                        showSearch={false}
                                    />}
                                {defaultFilterList.includes('Facebook Forms') && <CheckAccordion
                                    value={fbFormValue}
                                    setValue={setFbFormValue}
                                    selectionValues={fbFormList}
                                    accordionName="fb_forms"
                                    label="Facebook Forms"
                                />}
                                {defaultFilterList.includes('Industry') &&
                                    <CheckAccordion
                                        value={industry}
                                        setValue={setIndustry}
                                        selectionValues={propertyEnums.enum_industry}
                                        accordionName="Industry"
                                        label="Industry"
                                    />}
                                {defaultFilterList.includes('Re-assign Date') && <div className="filter-card cursor-pointer">
                                    <div className="filter-header collapsed" data-toggle="collapse" data-target="#collapsereassigneddate" aria-expanded="false" aria-controls="collapsereassigneddate" id="headingReassignDate">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <h2 className="fz16 black mb-0 fw-regular">
                                                Re-assign Date
                                                {Object.keys(reassignDate).length > 0 && <span>{Object.keys(reassignDate).length}</span>}
                                            </h2>
                                            <div className="icon mr-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#92929D" />
                                            </svg></div>
                                        </div>
                                    </div>
                                    <div id="collapsereassigneddate" className="filter-body collapse" aria-labelledby="headingReassignDate" data-parent="#accordion">
                                        <div className="d-flex justify-content-between align-center mx-3" >
                                            <div className='mr-8 mb-20 w-100'>
                                                <Form.Label>
                                                    Start Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reassignDate.startDate ? new Date(reassignDate.startDate) : ''}
                                                    placeholderText={'Start Date'}
                                                    maxDate={new Date()}
                                                    onChange={(date) => handleReassignDateChange('startDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <Form.Label>
                                                    End Date
                                                </Form.Label>
                                                <DatePicker
                                                    selected={reassignDate.endDate ? new Date(reassignDate.endDate) : ''}
                                                    placeholderText={'End Date'}
                                                    maxDate={new Date()}
                                                    minDate={reassignDate.startDate}
                                                    onChange={(date) => handleReassignDateChange('endDate', date)}
                                                    dateFormat="dd MMM yyyy"
                                                    className={`form-control text-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {defaultFilterList.includes('EOI Cancellation') || defaultFilterList.includes('Token Cancellation') && <CheckAccordion
                                    value={eoiCancel}
                                    setValue={setEoiCancel}
                                    selectionValues={EOICancelReasonList}
                                    accordionName="eoi_cancellation"
                                    label="Token Cancellation"
                                    showSearch={true}
                                />}
                                {defaultFilterList.includes('Booking Cancellation') && <CheckAccordion
                                    value={bookingCancel}
                                    setValue={setBookingCancel}
                                    selectionValues={EOICancelReasonList}
                                    accordionName="booking_cancellation"
                                    label="Booking Cancellation"
                                    showSearch={true}
                                />}
                                {defaultFilterList.includes('Purpose') &&
                                    <CheckAccordion
                                        value={purpose}
                                        setValue={setPurpose}
                                        selectionValues={propertyEnums.enum_purpose}
                                        accordionName="Purpose"
                                        label='Purpose'
                                    />}
                                {defaultFilterList.includes('Property Type') &&
                                    <CheckAccordion
                                        value={propertyType}
                                        setValue={setPropertyType}
                                        selectionValues={propertyEnums.enum_property_category}
                                        accordionName="Property_Type"
                                        label='Property Type'
                                        showSearch={false}
                                    />}
                                {defaultFilterList.includes('Requirement Type') &&
                                    <CheckAccordion
                                        value={requirementType}
                                        setValue={setRequirementType}
                                        selectionValues={propertyEnums.enum_property_type}
                                        accordionName="Requirement_Type"
                                        label='Requirement Type'
                                    />}


                                {defaultFilterList.includes('Employment Type') &&
                                    <CheckAccordion
                                        value={employmentType}
                                        setValue={setEmploymentType}
                                        selectionValues={employment_list}
                                        accordionName="Employment_Type"
                                        label='Employment Type'
                                        showSearch={false}
                                    />}
                                {defaultFilterList.includes('Facing') &&
                                    <CheckAccordion
                                        value={facing}
                                        setValue={setFacing}
                                        selectionValues={propertyEnums.enum_facing}
                                        accordionName="Facing"
                                        label='Facing'
                                        showSearch={false}
                                    />}
                                {defaultFilterList.includes('Possession') &&
                                    <CheckAccordion
                                        value={possession}
                                        setValue={setPossession}
                                        selectionValues={propertyEnums.enum_possession}
                                        accordionName="Possession_Preference"
                                        label='Possession Preference'
                                        showSearch={false}
                                    />}
                                {defaultFilterList.includes('Configuration Type') && <StaticFieldAccordian
                                    accordionName={"Configuration_Type"}
                                    label={'Configuration Type'}
                                    value={configuration}
                                    placeholder='e.g. 2 bhk'
                                    setValue={setConfigurtion}
                                    defaultExapanded={false}
                                />}
                                {defaultFilterList.includes('Customer Address') && <StaticFieldAccordian
                                    accordionName={"Customer_Address"}
                                    label={'Customer Address'}
                                    value={customerAddress}
                                    placeholder='Search by location'
                                    setValue={setCustomerAddress}
                                    defaultExapanded={false}
                                />}

                                {defaultFilterList.includes('Referred By') && <StaticFieldAccordian
                                    accordionName={"Referred_By"}
                                    label={'Referred By'}
                                    value={referredBy}
                                    placeholder='Search by Referred By'
                                    setValue={setReferredBy}
                                    defaultExapanded={false}
                                />}
                                {defaultFilterList.includes('CSV Import') && <CheckAccordion
                                    value={csvVal}
                                    setValue={setCSVVal}
                                    selectionValues={csvList}
                                    accordionName="CSV"
                                    label="CSV Import"
                                />}


                                {/* <CheckAccordion
                                    value={[]}
                                    setValue={() => { }}
                                    selectionValues={followupStatuses}
                                    accordionName="pending_followups"
                                    label="Pending Followups"
                                />

                                <CheckAccordion
                                    value={[]}
                                    setValue={() => { }}
                                    selectionValues={followupStatuses}
                                    accordionName="pending_site_visits"
                                    label="Pending Site Visits"
                                />
                                <CheckAccordion
                                    value={[]}
                                    setValue={() => { }}
                                    selectionValues={followupStatuses}
                                    accordionName="booking_approval_status"
                                    label="Booking Approval Status"
                                />
                                <CheckAccordion
                                    value={[]}
                                    setValue={() => { }}
                                    selectionValues={approvalStatuses}
                                    accordionName="eoi_spproval_status"
                                    label="EOI Approval Status"
                                />
                                <StaticFieldAccordian
                                    accordionName={"sv_completed"}
                                    label={'No. of SV Completed'}
                                    value={preferredLocation}
                                    placeholder='No. of SV Completed'
                                    setValue={setPreferredLocation}
                                    defaultExapanded={false}
                                    type='number'
                                /> */}

                                {defaultFilterList.includes('Revisit') && <CheckAccordion
                                    value={revisit}
                                    setValue={setRevisit}
                                    selectionValues={[{
                                        label: 'Show Revisit',
                                        value: 'revisit'
                                    }]}
                                    accordionName="re-visit"
                                    label="SV Revisit"
                                    showSearch={false}
                                />}
                                {/* {defaultFilterList.includes('Hide Team Leads') && <CheckAccordion
                                    value={hideTeamLeads}
                                    setValue={setHideTeamLeads}
                                    selectionValues={[{
                                        label: 'Hide Team Leads',
                                        value: true
                                    }]}
                                    accordionName="hide_team_leads"
                                    label="Hide Team Leads"
                                    showSearch={false}
                                />} */}

                                {defaultFilterList.includes('Teams') &&
                                    <CheckAccordion
                                        value={team}
                                        setValue={setTeam}
                                        selectionValues={teamList}
                                        accordionName="teams"
                                        label="Teams"
                                        showSearch={true}
                                    />}

                                {
                                    (defaultFilterList.includes('Sourcing manager') && userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) &&
                                    <DynamicAccordion
                                        accordionName="Sourcing Manager"
                                        placeholder='Enter user name to search'
                                        value={selectedRM}
                                        setValue={setSelectedRM}
                                        searchResults={structuredClone(searchResults['Sourcing Manager'])}
                                        setSearchResults={(data) => setSearchResults({ ...searchResults, ["Sourcing Manager"]: structuredClone(data) })}
                                        inputValues={inputValues["Sourcing Manager"]}
                                        handleInputChange={handleInputChange}
                                        notFound={inputValuesNotFound["Sourcing Manager"]}
                                        setInputValues={setInputValues}
                                        inputValuesNotFound={inputValuesNotFound}
                                        setInputValuesNotFound={setInputValuesNotFound}
                                        searchDefultResults={searchDefultResults}
                                    />
                                }
                                {(defaultFilterList.includes('CP Firm') && userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) && <DynamicAccordion
                                    accordionName="CP Firm"
                                    placeholder='Enter CP Firm to search'
                                    value={brokerCompany}
                                    setValue={setBrokerCompany}
                                    searchResults={structuredClone(searchResults['CP Firm'])}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["CP Firm"]: structuredClone(data) })}
                                    inputValues={inputValues["CP Firm"]}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound["CP Firm"]}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                    searchDefultResults={searchDefultResults}
                                />}

                                {defaultFilterList.includes('Name') && <DynamicAccordion
                                    accordionName="Name"
                                    placeholder='Enter lead name to search'
                                    value={name}
                                    setValue={setname}
                                    searchResults={searchResults.Name}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, Name: data })}
                                    inputValues={inputValues.Name}
                                    handleInputChange={handleInputChange}

                                    notFound={inputValuesNotFound.Name}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                />}
                                {defaultFilterList.includes('City') && <DynamicAccordion
                                    accordionName="City"
                                    placeholder='Enter city to search'
                                    value={city}
                                    setValue={setCity}
                                    searchResults={searchResults.City}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, City: data })}
                                    inputValues={inputValues.City}
                                    handleInputChange={handleInputChange}
                                    notFound={inputValuesNotFound.City}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                />}
                                {/* {defaultFilterList.includes('Reasons') && <DynamicAccordion
                                    accordionName="Reasons"
                                    placeholder='Enter reason to search'
                                    value={reason}
                                    setValue={setReason}
                                    searchResults={searchResults.Reasons}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, Reasons: data })}
                                    inputValues={inputValues.Reasons}
                                    handleInputChange={handleReasonChange}
                                    notFound={inputValuesNotFound.Reasons}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}
                                />} */}

                                {agencyDetails.length > 0 && defaultFilterList.includes('Marketing Agency') && <CheckAccordion
                                    value={agency}
                                    setValue={setAgency}
                                    selectionValues={agencyDetails}
                                    accordionName="Agency"
                                    label="Marketing Agency"
                                />}

                                {defaultFilterList.includes('Mobile No') && <DynamicAccordion
                                    accordionName="Mobile No"
                                    type='number'
                                    value={mobile}
                                    placeholder='e.g. 9340000008'
                                    setValue={setmobile}
                                    searchResults={searchResults["Mobile No"]}
                                    setSearchResults={(data) => setSearchResults({ ...searchResults, ["Mobile No"]: data })}
                                    inputValues={inputValues["Mobile No"]}
                                    notFound={inputValuesNotFound["Mobile No"]}
                                    handleInputChange={handleInputChange}
                                    setInputValues={setInputValues}
                                    inputValuesNotFound={inputValuesNotFound}
                                    setInputValuesNotFound={setInputValuesNotFound}

                                />}

                                {defaultFilterList.includes('Email') && <StaticFieldAccordian
                                    accordionName={"Email"}
                                    type='email'
                                    label={'Email'}
                                    value={email}
                                    placeholder='Enter email to search'
                                    setValue={setEmail}
                                    defaultExapanded={false}
                                />}

                                {defaultFilterList.includes('Alt. Mobile') && <StaticFieldAccordian
                                    accordionName={"alt_mobile"}
                                    label={'Alt. Mobile'}
                                    value={altMobile}
                                    placeholder='e.g. 9340000008'
                                    setValue={setAltMobile}
                                    defaultExapanded={false}
                                    type='number'
                                />}

                                {(defaultFilterList.includes('CP User Code') && userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID) && <StaticFieldAccordian
                                    accordionName={"Broker_code"}
                                    label={'CP User Code'}
                                    value={brokerCode}
                                    placeholder='Search by CP User Code'
                                    setValue={setBrokerCode}
                                    defaultExapanded={false}
                                />}
                                {/* {defaultFilterList.includes('CP User') &&
                                    <CPUserAccordian
                                        selectedCompany={cpUser}
                                        setSelectedCompany={setCPUser}
                                        accordionName="cp_user"
                                        label='CP User'
                                        brokerCompanyList={brokerCompanyList}
                                        setBrokerCompanyList={setBrokerCompanyList}
                                        getCompanyName={getCompanyName}
                                    />} */}
                            </div>
                        </div>
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={() => setShow(false)}>Cancel</button>
                        <button className="pr-btn" onClick={sendFilterRequest}>Apply Filter</button>
                    </div>
                </>
            }
        />
    )
}