import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';
import URLS from '../../helpers/urls';


export async function getMasterEnumsCall() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMasterEums}`, null, null, null);
}

// {
// key: intent_id || lead data column values
// value: data to be updated
// }
export async function updateleadByKeyValue(uuid, { key, value }) {
    return request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.updateByKeyValue}/${uuid}`, null,
        {
            key,
            value
        }
        , null);
}

export async function getALLLeads(payload) {
    let limit = payload.limit || 10;
    let pageNumber = payload.pageNumber || 1;
    let filters = payload.filters
    let sort = payload.sort
    let col = payload.col
    let text = payload.text
    let filterType = payload.filterType
    let site_visits_filter = payload.site_visits_filter


    let url = `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/dashboard`;
    let body = { limit, pageNumber, filters, sort, col, text, filterType, site_visits_filter }
    // switch(payload.type){
    // case 'Search':
    //      body['text'] = payload.text;
    //      break;
    // case 'Sort':
    //       body['col'] = payload.text;
    //       body['sort'] = payload.direction; 
    //       break; 

    // case 'Filters':
    //       body['filters'] = payload.filters;
    //       break;       

    // }

    return request("POST", url, null, body, null)

}

export async function getLeadCounts(payload) {
    let limit = payload.limit || 10;
    let pageNumber = payload.pageNumber || 1;
    let filters = payload.filters
    let sort = payload.sort
    let col = payload.col
    let text = payload.text
    let filterType = payload.filterType
    let site_visits_filter = payload.site_visits_filter

    let url = `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/getLeadCounts`;
    let body = { limit, pageNumber, filters, sort, col, text, filterType, site_visits_filter }
    // switch(payload.type){
    // case 'Search':
    //      body['text'] = payload.text;
    //      break;
    // case 'Sort':
    //       body['col'] = payload.text;
    //       body['sort'] = payload.direction; 
    //       break; 

    // case 'Filters':
    //       body['filters'] = payload.filters;
    //       break;       

    // }

    return request("POST", url, null, body, null)

}

export async function getExportLead(payload) {
    let limit = payload.limit || 10;
    let pageNumber = payload.pageNumber || 1;
    let filters = payload.filters
    let sort = payload.sort
    let col = payload.col
    let text = payload.text
    let filterType = payload.filterType

    let url = `${process.env.REACT_APP_DEV_API_URL}/${URLS.LEAD_URL}/exportAllLeadsToCSV`;
    let body = { filters, sort, col, text, filterType, limit, pageNumber }


    return request("POST", url, null, body, null)

}

export async function getCompanyConfig() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getConfig}`, null, null, null)
}

export async function getLeadReEngagedSources(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getLeadReEngagedSources}/${uuid}`, null, null, null)
}


export async function getUserProfile() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUserProfile}`, null, null, null)
}

export async function removeDeviceByFirebaseToken(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.removeDeviceByFirebaseToken}`, null, payload, null)
}

export async function getUsersForSettings(searchText, role, is_active) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUsersForSettings}`, { searchText, role, is_active }, null, null)
}

// Dashboard
export async function getPlusAppFilterUsers(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getCompaniesForAnalytics}`, null, payload, null);
}

export async function getCompaniesForAnalytics(filters, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.getCompaniesForAnalytics}`, payload, filters, null)
}

export async function addBrokerCompany(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addBrokerCompany}`, null, payload, null)
}

export async function updateBrokerCompany(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBrokerCompany}/${uuid}`, null, payload, null)
}

export async function getMicromarket(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMicromarket}`, { searchText }, null, null)
}

export async function addBrokerUser(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addBrokerUser}/${uuid}`, null, payload, null)
}

export async function editBrokerUser(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.editBrokerUser}/${uuid}`, null, payload, null)
}

export async function getAllBrokersUnderRm() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllBrokersUnderRm}`, null, null, null)
}

export async function editBrokerCode(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.editBrokerCode}/${uuid}`, null, payload, null)
}

export async function removeLeadTag(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.removeLeadTag}/${uuid}`, null, null, null)
}

export async function makeCompanyPremiumByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.makeCompanyPremiumByUUID}${"/" + uuid}`, null, null, null)
}
export async function getAllRequests(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllRequests}`, searchText, null, null)
}

export async function updateStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateStatusByUUID}/${uuid}`, null, payload, null)
}

export async function updateRm(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateRm}/${uuid}`, null, payload, null)
}
export async function updateRemarksByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateRemarksByUUID}/${uuid}`, null, payload, null)
}

export async function saveCallRequestedNote(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveCallRequestedNote}/${uuid}`, null, payload, null)
}
export async function updateCallRequestedNote(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateCallRequestedNote}/${uuid}`, null, payload, null)
}
export async function getCallReqeustedNotes(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getCallReqeustedNotes}/${uuid}`, null, null, null)
}

export async function saveCompanyNote(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveCompanyNote}/${uuid}`, null, payload, null)
}
export async function updateCompanyNote(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateCompanyNote}/${uuid}`, null, payload, null)
}
export async function getCompanyNotes(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getCompanyNotes}/${uuid}`, null, null, null)
}

export async function addBanner(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addBanner}`, null, payload, null)
}

export async function makeCompanyFreemium(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.makeCompanyFreemium}/${uuid}`, null, payload, null)
}
export async function getAllBanners() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllBanners}`, null, null, null)
}

export async function updateBannerByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBannerByUUID}/${uuid}`, null, payload, null)
}

export async function updateBannerVisibilityByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateBannerVisibilityByUUID}/${uuid}`, null, payload, null)
}

export async function getBannerByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getBannerByUUID}/${uuid}`, null, null, null)
}

export async function getProjects(payload, searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjects}`, { searchText }, payload, null)
}

export async function getAllProjects() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllProjects}`, null, null, null)
}

//  Event Lanuchpad 

export async function getEventDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getEventDetail}/${uuid}`, null, null, null)
}

export async function insertEvent(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertEvent}`, null, payload, null)
}

export async function updateEventByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateEventByUUID}/${uuid}`, null, payload, null)
}

export async function getEvents(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllEvents}`, { searchText }, null, null)
}


export async function updateEventStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateEventStatusByUUID}/${uuid}`, null, payload, null)
}

export async function submitEventForReview(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.submitEventForReview}/${uuid}`, null, null, null)
}

export async function getRoles() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getRoles}`, null, null, null)
}
export async function updateCompanyInfo(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateCompanyInfo}`, null, payload, null)
}

export async function getDashboardCounts() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getDashboardCounts}`, null, null, null)
}

export async function inviteUser({ name, email, role, mobile }) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.inviteUser}`, null, {
        name,
        email,
        role,
        mobile
    }, null)
}

export async function editUser({ name, email, role, mobile, uuid }) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.editUser}${"/" + uuid}`, null, {
        name,
        email,
        role,
        mobile
    }, null)
}

export async function editCompany({ uuid, name, owner_name, owner_mobile, owner_email, is_subscribed, subscription_expiry }) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateCompanyInfoByUUID}${"/" + uuid}`, null, {
        name,
        owner_name,
        owner_mobile,
        owner_email,
        is_subscribed,
        subscription_expiry
    }, null)
}

export async function deleteUser(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteUser}${"/" + uuid}`, null, null, null)
}

export async function deleteUserByUuid(uuid, userId) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteUserByUuid}${"/" + uuid}`, null, { userId }, null)
}

export async function markUserActiveByUuid(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markUserActiveByUuid}${"/" + uuid}`, null, {}, null)
}
export async function markUserInactiveByUuid(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markUserInactiveByUuid}${"/" + uuid}`, null, {}, null)
}

export async function updateSmsNotification(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateSmsNotification}/${uuid}`, null, payload, null)
}

export async function updateNewLeadNotification(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateNewLeadNotification}/${uuid}`, null, payload, null)
}

export async function updateSiteVisitNotification(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateSiteVisitNotification}/${uuid}`, null, payload, null)
}

export async function resetUserPassword(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.resetUserPassword}${"/" + uuid}`, null, null, null)
}

export async function updatePassword(currentPassword, password) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.updatePassword}`, null, {
        currentPassword,
        password
    }, null)
}

export async function getCompanyDetails() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getDetails}`, null, null, null)
}

export async function addProject(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertProject}`, null, { ...payload }, null)
}

export async function editProject({ name, developer, area, city, uuid, state, district, country, zip_code }) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.editProject}${"/" + uuid}`, null, {
        name,
        developer,
        area,
        city,
        state,
        district,
        country,
        zip_code
    }, null)
}

export async function getProject(projectId) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProject}/${projectId}`, null, null, null)
}

export async function getTimelineCall(leadUUID) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getTimeline}/${leadUUID}`, null, null);
}

export async function getPersonalInfo() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUserInfo}`, null, null, null)
}

export async function updateOwnerByKeyAndValue(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateOwnerByKeyAndValue}`, null, payload, null)
}

export async function addProjectFormId(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addProjectFormId}`, null, payload, null)
}

export async function updateProjectMarketingAgency(formId, marketingAgency) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateProjectMarketingAgency}/${formId}`, null, marketingAgency, null)
}

export async function getProjectFormIds(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getProjectFormIds}`, { searchText }, null, null)
}

export async function getMakeIntegrationRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMakeIntegrationRouting}/${uuid}`, null, null, null)
}

export async function getAllApikeys() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllApikeys}`, null, null, null)
}

export async function deleteProjectFormId(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteProjectFormId}${"/" + uuid}`, null, null, null)
}

export async function updateRolePermissions(currentRoleUUID, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateRolePermissions}${"/" + currentRoleUUID}`, null, payload, null)
}

export async function getRolePermissions(currentRoleUUID) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getRolePermissions}${"/" + currentRoleUUID}`, null, null, null)
}

export async function getLandingPageIntegration(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getLandingPage}`, { searchText }, null, null)
}

export async function addPageIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addPageIntegration}`, null, payload, null)
}

export async function updatePageIntegration(payload, uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updatePageIntegration}/${uuid} `, null, payload, null)
}

export async function markLeadInactive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markInactive}${"/" + uuid}`, null, {}, null)
}

export async function markLeadActive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markActive}${"/" + uuid}`, null, {}, null)
}

export async function getProjectAddress(searchString) {
    return await request(API_METHOD.GET, API_URLS.baseurl + API_URLS.company.areaByName + "?searchText=" + searchString, null, null, null)
}

export async function deleteProjectByUUID(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.deleteProjectByUuid}${"/" + uuid}`, null, {}, null)
}

export async function generateApikey() {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.generateApiKey}`, null, {
        serviceName: "Make Standup"
    }, null)
}

export async function getAuthRolePermissions() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.auth.getRolePermissions}`, null, null, null)
}

export async function getProjectIntegrations(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.projectIntergration}/${uuid}`, null, null, null)
}

export async function markRoutingEnable() {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markRoutingEnable}`, null, {}, null)
}

export async function markRoutingDisable() {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.markRoutingDisable}`, null, {}, null)
}

export async function getExportLimitNumber(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getExportLimit}`, null, null, null)
}

export async function getSubscription() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getSubscriptionExpiry}`, null, null, null)
}

export async function updateReEngagement(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateReEngagement}`, null, payload, null)
}

export async function getReEngagement() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getReEngagementStatus}`, null, null, null)
}
export async function updateAlternateNumberDuplicacy(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAlternateNumberDuplicacy}`, null, payload, null)
}

export async function knowlarityClickToCall(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.knowlarityClickToCall}/${uuid}`, null, payload, null)
}

export async function knowlarityClickToCallAdvanced(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.knowlarityClickToCallAdvanced}/${uuid}`, null, payload, null)
}
//  Custom Source 
export async function getAllSourcesData() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllSourcesData}`, null, null, null)
}

export async function addCustomSource(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.addCustomSource}`, null, payload, null)
}

export async function deleteSource(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteSource}/${uuid}`, null, null, null)
}
export async function renameSource(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.renameSource}/${uuid} `, null, payload, null)
}

// Automation 
export async function getSvCompletedReadAccess() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getSvCompletedReadAccess}`, null, null, null)
}

export async function updateSvCompletedReadAccess(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateSvCompletedReadAccess}`, null, payload, null)
}

export async function updateAutoLeadShare(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoLeadShare}`, null, payload, null)
}

export async function updateAutoUnassignedLeadDistribution(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoUnassignedLeadDistribution}`, null, payload, null)
}

export async function saveUnassignedLeadDistributionUsers(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.saveUnassignedLeadDistributionUsers}`, null, payload, null)
}

export async function getUnassignedLeadDistributionUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUnassignedLeadDistributionUsers}`, null, null, null)
}

export async function updateAutoPendingFollowupAssignment(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoPendingFollowupAssignment}`, null, payload, null)
}

export async function getPendingFollowupAssignment() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getPendingFollowupAssignment}`, null, null, null)
}

export async function savePendingFollowupAssignment(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.savePendingFollowupAssignment}`, null, payload, null)
}

export async function updateAutoEoiCancel(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoEoiCancel}`, null, payload, null)
}

export async function updateAutoCancelledEoiAssignment(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoCancelledEoiAssignment}`, null, payload, null)
}

export async function getCancelledEoiAssignmentUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getCancelledEoiAssignmentUsers}`, null, null, null)
}

export async function saveCancelledEoiAssignmentUsers(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.saveCancelledEoiAssignmentUsers}`, null, payload, null)
}

export async function updateAutoUnassignedLeadProjectDistribution(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoUnassignedLeadProjectDistribution}`, null, payload, null)
}

export async function getUnassignedLeadProjectDistribution() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUnassignedLeadProjectDistribution}`, null, null, null)
}

export async function saveUnassignedLeadProjectDistribution(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveUnassignedLeadProjectDistribution}`, null, payload, null)
}

export async function updateAutoDeadLeadToNewLead(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAutoDeadLeadToNewLead}`, null, payload, null)
}

export async function insertFinalNegotiationAutomationTeams(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertFinalNegotiationAutomationTeams}`, null, payload, null)
}

export async function getFinalNegotiationAutomationTeams() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getFinalNegotiationAutomationTeams}`, null, null, null)
}

export async function updateFinalNegotationAutomation(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotationAutomation}`, null, payload, null)
}

export async function updateFinalNegotiationColdIntentAutomation(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotiationColdIntentAutomation}`, null, payload, null)
}

export async function updateFinalNegotationAutomationTeamStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotationAutomationTeamStatusByUUID}/${uuid}`, null, payload, null)
}

export async function updateFinalNegotationAutomationWithoutTeamStatus(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotationAutomationWithoutTeamStatus}/${uuid}`, null, payload, null)
}

export async function updateFinalNegotationAutomationWithoutTeamUsers(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotationAutomationWithoutTeamUsers}/${uuid}`, null, payload, null)
}

export async function updateFinalNegotationAutomationWithoutTeamAssignedTo(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateFinalNegotationAutomationWithoutTeamAssignedTo}/${uuid}`, null, payload, null)
}

export async function deleteFinalNegotationAutomationTeamsConfigByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.deleteFinalNegotationAutomationTeamsConfigByUUID}/${uuid}`, null, null, null)
}

export async function getFinalNegotationAutomationWithoutTeamConfig() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getFinalNegotationAutomationWithoutTeamConfig}`, null, null, null)
}

export async function insertFinalNegotiationAutomationWithoutTeam() {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertFinalNegotiationAutomationWithoutTeam}`, null, {}, null)
}


export async function getAllTeamLeads() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllTeamLeads}`, null, null, null)
}


export async function updateNewLeadReEngagement(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateNewLeadReEngagement}`, null, payload, null)
}


export async function getNewLeadReEngagmentUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getNewLeadReEngagmentUsers}`, null, null, null)
}

export async function saveNewLeadReEngagmentUsers(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveNewLeadReEngagmentUsers}`, null, payload, null)
}

export async function getDeadLeadReEngagmentUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getDeadLeadReEngagmentUsers}`, null, null, null)
}

export async function saveDeadLeadReEngagmentUsers(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveDeadLeadReEngagmentUsers}`, null, payload, null)
}

// Column Customisation

export async function saveColumnPreference(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveColumnPreference}`, null, payload, null)
}

export async function getColumnPreferenceById(id) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getColumnPreferenceById}/${id}`, null, null, null)
}

export async function getUserColumnPreferences() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUserColumnPreferences}`, null, null, null)
}

// Column Customisation New
export async function getColumnPreferenceNew(id, operation = 'get') {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getColumnPreferenceNew}/${id}`, { operation }, null, null)
}
export async function getCurrentUserColumnPreference() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getColumnPreferenceNew}`, null, null, null)
}

export async function saveColumnPreferenceNew(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveColumnPreferenceNew}`, null, payload, null)
}

// Sv tracker column Customisation

export async function getMasterColumnPreferenceForSvTracker() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMasterColumnPreferenceForSvTracker}`, null, null, null)
}

export async function getColumnPreferenceForSvTracker() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getColumnPreferenceForSvTracker}`, null, null, null)
}

export async function saveColumnPreferenceForSvTracker(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.saveColumnPreferenceForSvTracker}`, null, payload, null)
}

// lead integration page api's

export async function getUsersForLeadIntegration(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.routing.getUsersForIntegration}?name=${searchText}`, null, null, null)
}
export async function markRoutingLeadActive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.routing.markLeadActive}/${uuid}`, null, {}, null)
}
export async function markRoutingLeadInactive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.routing.markLeadInActive}/${uuid}`, null, {}, null)
}

export async function routingUpdateProject(uuid, projectId) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.routing.updateProjects}/${uuid}`, null, { projectId }, null)
}

export async function getLandingPageIntegrationRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getLandingPageIntegrationRouting}/${uuid}`, null, null, null)
}

//  Agency api's
export async function getCompanyAgency() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getAllCompanyAgency}`, null, null, null)
}

export async function insertCompanyAgency(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.company.insertAgency}`, null, payload, null)
}

export async function updateAgency(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.company.updateAgency}/${uuid}`, null, payload, null)
}

// Housing.com page api's

export async function insertNewIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.integration.housingIntegration}`, null, payload, null)
}

export async function getIntegrations(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getHousingIntegration}`, { searchText }, null, null)
}

export async function getHousingProfile(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getHousingProfiles}`, { searchText }, null, null)
}

export async function markHousingLeadActive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.markLeadActive}/${uuid}`, null, {}, null)
}
export async function markHoousingLeadInactive(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.markLeadInActive}/${uuid}`, null, {}, null)
}
export async function viewHousingIntegration(id) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.viewHousingIntegration}/${id}`, null, null, null)
}

export async function updateHousingIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.updateHousingIntegration}/${uuid}`, null, payload, null)
}

export async function housingToggleUnmappedLeads(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.housingToggleUnmappedLeads}/${uuid}`, null, payload, null)
}

export async function getHousingIntegrationRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getHousingIntegrationRouting}/${uuid}`, null, null, null)
}

// Magicbricks api

export async function magicbricksIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.integration.magicbricksIntegration}`, null, payload, null)
}

export async function getMagicbricksIntegrations(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getMagicbricksIntegration}`, { searchText }, null, null)
}

export async function getMagicbricksProfile(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getMagicbricksProfiles}`, { searchText }, null, null)
}

export async function updateMagicbricksIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.updateMagicbricksIntegration}/${uuid}`, null, payload, null)
}

export async function viewMagicbricksIntegration(id) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.viewMagicbricksIntegration}/${id}`, null, null, null)
}

export async function magicbricksToggleUnmappedLeads(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.magicbricksToggleUnmappedLeads}/${uuid}`, null, payload, null)
}

export async function getMagicbricksIntegrationRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.getMagicbricksIntegrationRouting}/${uuid}`, null, null, null)
}

// 99 Acres

export async function create99AcresIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.integration.create99AcresIntegration}`, null, payload, null)
}

export async function get99AcresIntegrations(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.get99AcresIntegration}`, { searchText }, null, null)
}

export async function get99AcersIntegrationRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.get99AcersIntegrationRouting}/${uuid}`, null, null, null)
}

export async function get99AcresProfiles(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.get99AcresProfiles}`, { searchText }, null, null)
}

export async function update99AcresIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.update99AcresIntegration}/${uuid}`, null, payload, null)
}

export async function view99AcresIntegration(id) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.integration.view99AcresIntegration}/${id}`, null, null, null)
}

export async function acresToggleUnmappedLeads(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.integration.acresToggleUnmappedLeads}/${uuid}`, null, payload, null)
}

// Facebook api's

export async function getFacebookLoginUrl() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getLoginApi}`, null, null, null);
}

export async function generateFacebookToken(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.generateAccessToken}`, null, payload, null);
}

export async function facebookLinkedAccounts() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getLinkedAccounts}`, null, null, null)
}

export async function getFacebookPages(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getPages}/${uuid}`, null, null, null)
}

export async function getFacebookPageForms(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.getPageForms}`, null, payload, null)
}

export async function getCRMValues() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getCRMValues}`, null, null, null);
}

export async function createFbIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.createIntegration}`, null, payload, null)
}

export async function getFbIntegrations(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getIntegrations}`, searchText, null, null);
}

export async function updateActiveState(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.facebook.udateActiveState}/${uuid}`, null, payload, null)
}
export async function updatePageActiveState(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.facebook.updateIntegratedFormActiveStateByUUID}/${uuid}`, null, payload, null)
}

export async function deleteIntergration(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.facebook.deleteIntergration}/${uuid}`, null, null, null)
}

export async function getMappingByFormId(id) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getFormMapping}/${id}`, null, null, null)
}

export async function getPageFormByIntegrationId(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getPageFormByIntegrationId}/${uuid}`, null, null, null)
}

export async function updateIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.facebook.updateIntegration}/${uuid}`, null, payload, null)
}

export async function getInetgrationsPageForms(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getInetgrationsForms}/${uuid}`, null, null, null);
}

export async function getFormMappingRouting(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getFormMappingRouting}/${uuid}`, null, null, null);
}
export async function getTestInetgrationsPageForms(uuid, testParams) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getInetgrationsForms}/${uuid}`, testParams, null, null);
}

export async function getImportJobs(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getImportJobs}/${uuid}`, null, null, null);
}

export async function getErrorInfo(formId) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getErrorInfo}/${formId}`, null, null, null);
}
export async function getUnprocessedLeads(formId) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getUnprocessedLeads}/${formId}`, null, null, null);
}

export async function getIntegratedPageToEdit(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.getIntegratedPageToEdit}/${uuid}`, null, null, null);
}

export async function sendTestLead(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.sendtestLead}/${uuid}`, null, payload, null)
}

export async function resolveError(formId, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.resolveError}/${formId}`, null, payload, null)
}

export async function importPreviousLeads(id, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.facebook.importPreviousLeads}/${id}`, null, payload, null)
}

export async function deleteAccount(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.facebook.deleteAccount}/${uuid}`, null, null, null)
}

//  Roles 

export async function createRoles(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.roles.create}`, null, payload, null)
}
export async function getPermissions(searchText) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.getMasterPermissions}`, { searchText }, null, null)
}
export async function setPermissions(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.setPermissions}/${uuid}`, null, payload, null)
}

export async function updateRolesPermissions(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.updateRolesPermissions}`, null, payload, null)
}

export async function getExistingUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.getExistingUsers}`, null, null, null)
}

export async function addExistingUser(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.addExistingUser}/${uuid}`, null, payload, null)
}
export async function addNewUser(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.addNewUser}/${uuid}`, null, payload, null)
}
export async function getAddedUsers(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.getUsers}/${uuid}`, null, null, null)
}
export async function addUserTeam(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.addUserTeam}/${uuid}`, null, payload, null)
}
export async function addReportingUser(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.addReportingUser}/${uuid}`, null, payload, null)
}
export async function addNewProject(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.addProject}/${uuid}`, null, payload, null)
}

export async function cancelInvite(uuid) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.cancelInvite}/${uuid}`, null, {}, null)
}

export async function updateRoleDetails(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.updateRoleBasicDetails}/${uuid}`, null, payload, null)
}

export async function getMasterRoles() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.getMasterRoles}`, null, null, null)
}

export async function deletedRoleByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.deletedRoleByUUID}/${uuid}`, null, null, null)
}

export async function getRoleDetail(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.getRoleDetail}/${uuid}`, null, null, null)
}

export async function updateTeamLead(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.roles.updateTeamLead}/${uuid}`, null, payload, null)
}

export async function generateUserInviteLink(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.roles.generateUserInviteLink}/${uuid}`, null, null, null)
}

// IVR Integration

export async function getMyOperatorApikey() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getMyOperatorApikey}`, null, null, null)
}

export async function generateMyOperatorApikey() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.generateMyOperatorApikey}`, null, null, null)
}

export async function addMyOperatorIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.ivr.addMyOperatorIntegration}`, null, payload, null)
}

export async function addUserMapping(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.ivr.addUserMapping}/${uuid}`, null, payload, null)
}

export async function getIvrIntegrations() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getIvrIntegrations}`, null, null, null)
}

export async function getIvrUserMapping(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getIvrUserMapping}/${uuid}`, null, null, null)
}

export async function getIvrIntegrationByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getIvrIntegrationByUUID}/${uuid}`, null, null, null)
}

export async function updateMyOperatorIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.ivr.updateMyOperatorIntegration}/${uuid}`, null, payload, null)
}

export async function getCallLogStats() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getCallLogStats}`, null, null, null)
}

export async function getCallLogs(perPage, pageNumber) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getCallLogs}`, { perPage, pageNumber }, null, null)
}

// Knowlarity

export async function getKnowlarityIvrIntegrations() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityIvrIntegrations}`, null, null, null)
}

export async function getKnowlarityIvrUserMapping(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityIvrUserMapping}/${uuid}`, null, null, null)
}

export async function getKnowlarityIvrUserCallerIdMapping(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityIvrUserCallerIdMapping}/${uuid}`, null, null, null)
}

export async function addKnowlarityIntegration(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.ivr.addKnowlarityIntegration}`, null, payload, null)
}

export async function addKnowlarityUserMapping(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.ivr.addKnowlarityUserMapping}/${uuid}`, null, payload, null)
}

export async function addKnowlarityUserCallerIDMapping(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.ivr.addKnowlarityUserCallerIDMapping}/${uuid}`, null, payload, null)
}

export async function getKnowlarityIvrCallerIdByCompanyId() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityIvrCallerIdByCompanyId}`, null, null, null)
}

export async function getKnowlarityIvrIntegrationByUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityIvrIntegrationByUUID}/${uuid}`, null, null, null)
}

export async function updateKnowlarityIntegration(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.ivr.updateKnowlarityIntegration}/${uuid}`, null, payload, null)
}

export async function getKnowlarityCallLogStats() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityCallLogStats}`, null, null, null)
}

export async function getKnowlarityCallLogs(perPage, pageNumber) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.ivr.getKnowlarityCallLogs}`, { perPage, pageNumber }, null, null)
}

// Leads share access
export async function saveLeadAccess(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.saveLeadAccess}/${uuid}`, null, payload, null)
}
export async function getLeadAccess(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getLeadAccess}/${uuid}`, null, null, null)
}

export async function getLeadStatusShareConfig() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.lead.getLeadStatusShareConfig}`, null, null, null)
}

export async function saveLeadStatusShareConfig(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.saveLeadStatusShareConfig}`, null, payload, null)
}

export async function saveLeadStatusAssignedToConfig(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.saveLeadStatusAssignedToConfig}`, null, payload, null)
}

export async function saveLeadStatusSharedWithConfig(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.saveLeadStatusSharedWithConfig}`, null, payload, null)
}

export async function getLeadStatusShareConfigByStatusId(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.getLeadStatusShareConfigByStatusId}`, null, payload, null)
}

export async function getLeadStatusAssignedToConfigByStatusId(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.lead.getLeadStatusAssignedToConfigByStatusId}`, null, payload, null)
}

export async function bulkLeadShare(payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.lead.bulkLeadShare}`, null, payload, null)
}

export async function getMyUsers() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getMyUsers}`, null, null, null)
}

export async function getUniqueFacebookForms() {
    return request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.company.getUniqueFacebookForms}`, null, null, null);
}