import React, { useEffect, useState } from 'react'
import SvAutomationCard from './cards/automationCard';
import UnAssignCard from './cards/UnAssignCard';
import StatusAutomationCard from './cards/statusAutomationCard';
import FollowupCard from './cards/FollowupCard';
import EOICancelCard from './cards/EOICancelCard';
import ReassignEoiCancel from './cards/ReassignEoi';
import ProjectWiseDistributionCard from './cards/ProjectWiseDistributionCard';
import TeamsFollowupCard from './cards/TeamFollowupCard';
import DeadLeadReengagementCard from './cards/DeadLeadReengagementCard';
import FinalNegotiationCard from './cards/FinalNegotiationCard';
import { getUserProfile } from '../../../services/private/company.service';
import NewLeadReengagementCard from './cards/NewLeadReengagementCard';
import FinalNegotiationColdCard from './cards/FinalNegotiationColdCard';


const LeadAutomation = () => {
    const [userData, setUserData] = useState();

    const getUserConfiData = async () => {
        const res = await getUserProfile();
        if (res.data.status === 200) {
            setUserData(res.data.data)
            // setFinalNegotiationAutomation(res.data.data?.final_negotiation_automation)
        }
    }
    useEffect(() => {
        getUserConfiData()
    }, [])
    return (
        <div className="roles w-100">
            <div className="settings-title mb-16">
                <div className="w-100 flex justify-between">
                    <div className="w-1/2">
                        <h2 className="text-2xl  mb-2 black fw-po-medium flex items-center">
                            Lead Automation
                        </h2>
                        {/* <p className="fz16 black-dark-700 mb-0">
                            Automate your system flows for ease of usability.
                        </p> */}
                    </div>
                </div>
            </div>

            <div className='pl-6 pr-6 grid grid-cols-3 gap-x-4 gap-y-5 overflow-y-auto h-[74vh]'>
                <StatusAutomationCard getUserConfiData={getUserConfiData} userData={userData} />
                <UnAssignCard getUserConfiData={getUserConfiData} userData={userData} />
                <ProjectWiseDistributionCard getUserConfiData={getUserConfiData} userData={userData} />
                <SvAutomationCard getUserConfiData={getUserConfiData} userData={userData} />
                <FollowupCard getUserConfiData={getUserConfiData} userData={userData} />
                <EOICancelCard getUserConfiData={getUserConfiData} userData={userData} />
                {/* <TeamsFollowupCard /> */}
                <ReassignEoiCancel getUserConfiData={getUserConfiData} userData={userData} />
                <DeadLeadReengagementCard getUserConfiData={getUserConfiData} userData={userData} />
                <NewLeadReengagementCard getUserConfiData={getUserConfiData} userData={userData} />
                <FinalNegotiationCard getUserConfiData={getUserConfiData} userData={userData} />
                <FinalNegotiationColdCard getUserConfiData={getUserConfiData} userData={userData} />
            </div>
        </div>
    )
}

export default LeadAutomation