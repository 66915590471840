import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import SearchBox from '../../components/SearchBox';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import BookindDetailsModal from './Modal/bookindDetails';
import DetailsModal from './Modal/details';
import UnitDetails from './Modal/unitDetails';
import EditUnitDetails from './Modal/editUnitDetails';
import TableView from './views/tableView';
import { getProjectsForFilter } from '../../services/private/leads.service';
import { getDetailsByLeadId, getEOIDetails, getUnitBooking, getUnitStatusEnum, getUnits, getUnitsByMultipleProjectIds, updateUnitAvailability } from '../../services/private/inventory.service';
import { getInventoryUnits, getMultiplePriceingBasePrice, getPricingBasePrice, getProjectBlocks } from '../../services/public/public.service';
import InputSelect from '../../components/InputGroup/InputSelect';
import { getProjects } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import Consumer from '../../helpers/context';
import { ReactComponent as ViewlistIcon } from '../../assets/icons/Viewlist.svg';
import { ReactComponent as ViewgridIcon } from '../../assets/icons/Viewgrid.svg';
import GridView from './views/gridView';
import Tabs from '../../components/HOC/Tabs';
import { Dropdown } from 'react-bootstrap';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import SearchIcon from '../../assets/icons/search.svg'
import EOIModal from '../../components/Modals/EOIModal';
import BookingModal from '../../components/Modals/BookingModal';

const StatusName = {
    Blocked: 'Token',
    Unavailable: 'Hold'
}
const Inventory = () => {
    const [searchText, setSearchText] = useState('');
    const [showUnitDetailModal, setShowUnitDetailModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showUnitDetails, setShowUnitDetails] = useState(false);
    const [showEditUnitModal, setShowEditUnitModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState({});
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [tripleDot, setTripleDot] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [inventoryStats, setInventoryStats] = useState([]);
    const [inventoryStatsCount, setInventoryStatsCount] = useState({});
    const [basePriceData, setBasePriceData] = useState([]);
    const [basePriceConfigMap, setBasePriceConfigMap] = useState(null);
    const [isUnitTypePlot, setIsUnitTypePlot] = useState(false);
    const [listView, setListView] = useState(false);
    const [unitNameWiseCount, setUnitNameWiseCount] = useState({});
    const [selectedTab, setSelectedTab] = useState({});
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [showProject, setShowProject] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [gridProjectUnits, setGridProjectUnits] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [gripProjectSelected, setGridProjectSelected] = useState('')
    const [gridInventoryStats, setGridInventoryStats] = useState({});
    const [tabRowCount, setTabRowCount] = useState({});
    const [searchProject, setSearchProject] = useState('');
    const [allProject, setAllProjects] = useState([]);
    const [leadType, setLeadType] = useState('');
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [showfilter, setShowfilter] = useState(false)

    useDocumentTitle('Inventory');

    const { allowedPermissions, userProfile } = useContext(Consumer);
    /**
     * Base price config map
     * {
     *  config_id: rate (price)
     * }
     */

    const projectRef = useRef();

    const handleOutsideClick = (e) => {
        if (projectRef.current && !projectRef.current.contains(e.target)) {
            setShowProject(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, [showProject])

    const getAllProjects = async () => {
        const res = await getProjects();
        if (res.data.status === 200) {
            const mapped = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.uuid }))
            setProjects(mapped);
            setAllProjects(mapped)
            if (localStorage.getItem('inventoryProject')) {
                const projList = JSON.parse(localStorage.getItem('inventoryProject'))
                setSelectedProject(Array.isArray(projList) ? projList : [projList]);
                setGridProjectSelected(Array.isArray(projList) ? projList[0] : projList)
                const project = mapped.filter(i => {
                    if (Array.isArray(projList)) {
                        return projList?.includes(i.value)
                    } else {
                        return projList === i.value
                    }
                })
                setSelectedProjectName(project);
            } else {
                setSelectedProject([mapped[0].value]);
                localStorage.setItem('inventoryProject', JSON.stringify(mapped[0].value))
                setSelectedProjectName([mapped[0]])
                setGridProjectSelected(mapped[0].value);
            }
            // getProjectUnitLists(mapped[0].value);
        }
    }

    const getInventoryStats = async () => {
        const res = await getUnitStatusEnum();
        if (res.data.status === 200) {
            const list = res.data.data.map(item => ({ ...item, label: item.display_name, value: item.display_name }))
            setInventoryStats(list)
        }
    }

    useEffect(() => {
        getAllProjects();
        getInventoryStats();
        if (localStorage.getItem('inventoryProject')) {
            const projeList = JSON.parse(localStorage.getItem('inventoryProject'))
            setSelectedProject(Array.isArray(projeList) ? projeList : [projeList]);
            setGridProjectSelected(Array.isArray(projeList) ? projeList[0] : projeList)
        }
    }, []);

    const getProjectUnitLists = async () => {
        const payload = {
            projectIds: selectedProject,
            query: {
                status: selectedStatus,
                searchText
            }
        }
        // const res = await getUnits(uuid, showingResults, currentPage, payload);
        const res = await getUnitsByMultipleProjectIds(showingResults, currentPage, payload)
        if (res.data.status === 200) {
            // let unitCount = {
            //     Block: {},
            //     Floor: {}
            // }
            const isPlot = res.data.data.units.every(item => item.configuration.unit_type === 'Plot');
            // res.data.data.units.forEach(item => {
            //     if (item.configuration.unit_type === 'Plot') {
            //         if (!unitCount.Block[item.block]) {
            //             unitCount.Block[item.block] = 1
            //         } else {
            //             unitCount.Block[item.block] = unitCount.Block[item.block] + 1
            //         }
            //     }
            //     if (item.configuration.unit_type === 'Flat') {
            //         if (!unitCount.Floor[item.floor_no]) {
            //             unitCount.Floor[item.floor_no] = 1
            //         } else {
            //             unitCount.Floor[item.floor_no] = unitCount.Floor[item.floor_no] + 1
            //         }
            //     }
            // })
            // setUnitNameWiseCount(unitCount)
            setIsUnitTypePlot(isPlot)
            setUnitList(res.data.data.units)
            setInventoryStatsCount(res.data.data.stats);
            let stats = selectedStatus ? res.data.data.stats[selectedStatus] : res.data.data.stats.total;
            const totalPages = Math.ceil(
                Number(stats) / showingResults
            );
            setMaxPage(totalPages);
            setLeadsCount(stats);
        }
    }

    const getBasePriceData = async () => {
        const payload = {
            projectIds: selectedProject
        }
        // const res = await getPricingBasePrice(payload);
        const res = await getMultiplePriceingBasePrice(payload)
        if (res.data.status === 200) {
            setBasePriceData(res.data.data);
            if (res.data.data && res.data.data.length) {
                let temp = {};
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element.config && element.config.length) {
                        temp[element.config[0].id] = element.rate
                    }
                }
                if (Object.keys(temp).length) {
                    setBasePriceConfigMap(temp);
                }
            }
        }
    }

    const getEOILeadDetails = async (uuid, detailShowOrEdit) => {
        const res = await getDetailsByLeadId(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedUnit(res.data.data);
                if (detailShowOrEdit === 'view') {
                    setShowUnitDetailModal(true)
                    // setShowDetailsModal(true)
                } else {
                    setShowEditUnitModal(true);
                }
            }
        }
    }

    const getGridProjectTab = async () => {
        const res = await getProjectBlocks(gripProjectSelected)
        if (res.data.status === 200) {
            const list = res.data.data.map(i => ({ ...i, name: i.name.includes('Tower') ? i.name : `Tower ${i.name}` }))
            setTabList(list);
            let count = {}
            list.forEach(i => (
                count[i.name] = `${i.available_units}/${i.total_units}`
            ))
            setTabRowCount(count);
            setSelectedTab(list[0]);
            // setGridProjectSelected(res.data.data[0]);
            if (res.data.data?.length > 0) {
                getBlockWiseUnits(res.data.data[0]?.uuid)
            }
            if (res.data.data?.length === 0) {
                setGridProjectUnits([]);
                setGridInventoryStats({});
            }
        }
    }


    const getBlockWiseUnits = async (uuid) => {
        const payload = {
            tower_uuid: uuid,
            query: {
                status: selectedStatus,
                searchText
            }
        }
        // const res = await getUnits(uuid, showingResults, currentPage, payload);
        try {
            const res = await getInventoryUnits(gripProjectSelected, showingResults, currentPage, payload);
            if (res.data.status === 200) {
                setGridInventoryStats(res.data.data?.stats);
                let list = [...res.data.data.units]
                list.sort(function (a, b) {
                    var keyA = a.floor_no,
                        keyB = b.floor_no;
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                })
                setGridProjectUnits(list);
                // const tabName = tabList.find(i => i.uuid === uuid);
                // setTabRowCount({ ...tabRowCount, [tabName?.name]: res.data.data.units?.length });
            }
        } catch (err) {
            console.log({ err })
            setGridProjectUnits([])
        }
    }

    useEffect(() => {
        if (selectedProject.length) {
            getBasePriceData();
            if (listView) {
                getProjectUnitLists();
            } else {
                getBlockWiseUnits(selectedTab?.uuid)
            }
        }

    }, [selectedStatus, searchText, showingResults, currentPage, selectedProject])

    useEffect(() => {
        if (gripProjectSelected) {
            getGridProjectTab()
        }
    }, [gripProjectSelected]);

    useEffect(() => {
        if (selectedTab && Object.keys(selectedTab).length) {
            getBlockWiseUnits(selectedTab?.uuid)
        }
    }, [selectedTab])

    const handleChangeProject = useCallback((data) => {
        if (selectedProject.includes(data.value)) {
            const project = selectedProject.filter(item => item != data.value);
            const filteredName = selectedProjectName.filter(item => item.label != data.label);
            setSelectedProject(project);
            setSelectedProjectName(filteredName);
            localStorage.setItem('inventoryProject', JSON.stringify(project))
        } else {
            setSelectedProject([...selectedProject, data.value]);
            setSelectedProjectName([...selectedProjectName, data]);
            localStorage.setItem('inventoryProject', JSON.stringify([...selectedProject, data.value]))
        }
    }, [selectedProject]);

    const handleClearProjectSelection = useCallback(() => {
        if (selectedProjectList.length > 0) {
            setProjects([...projects, ...selectedProjectList])
            setSelectedProjectList([])
        }
        setSelectedProject([]);
        setSelectedProjectName([]);
        // getAllRecords();
    }, [selectedProjectList, projects]);

    const handleProjectSearch = (e) => {
        setSearchProject(e.target.value)
        const projects = allProject.filter(item => item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        setProjects(projects)
    }

    const handleCloseProject = useCallback(() => {
        const newItems = []
        selectedProject.forEach(item => {
            const index = projects.findIndex(list => list.value === item);
            newItems.push(projects[index])
            projects.splice(index, 1)
        })
        setSelectedProjectList(newItems);
    }, [selectedProject, projects]);

    const getSelectedUnitBookings = async (uuid, detailShowOrEdit, unit) => {
        try {
            const res = await getUnitBooking(uuid);
            if (res.data.status === 200) {
                setSelectedUnit(res.data.data);
                if (detailShowOrEdit === 'view') {
                    setShowUnitDetailModal(true)
                    // setShowDetailsModal(true)
                } else {
                    setShowEditUnitModal(true);
                }
            }
        } catch (err) {
            // toast.error(err.response.data.message);
            setSelectedUnit(unit);
            setShowUnitDetails(true)
        }
    }

    const handleMarkAvailableAndUnavailable = async (uuid, value) => {
        const res = await updateUnitAvailability(uuid, { status: value });
        if (res.data.status === 200) {
            getProjectUnitLists(selectedProject.value);
            toast.success(res.data.message);
        }
    }

    return (

        <div className="flex flex-col basis-[calc(100%-64px)] shrink-0 grow-0 w-[calc(100%-64px)] h-full">
            <div className='settings-title !mb-4 !pb-0'>
                <div className=" !mb-5">
                    <div className="flex flex-fill flex-wrap">
                        <div className="d-flex flex-fill an-title !border-b-0 !p-0 !mb-0 ">
                            <h1 className="mb-0 mr-2 fz24 black h-font fw-po-medium">Inventory </h1>
                            <div className="form-group w-[200px] ml-2 ">
                                {!listView && <InputSelect
                                    value={gripProjectSelected ? projects.find(i => i.value === gripProjectSelected) : ''}
                                    onChange={(val) => {
                                        setGridProjectSelected(val.value)
                                        // getGridProjectTab(val.value)
                                        localStorage.setItem('inventoryProject', JSON.stringify(val.value))
                                    }}
                                    options={projects}
                                    placeholder='Select Project'
                                    boxclass='!mr-0'
                                />}
                                {listView && <div className='anla-filter-area'>
                                    <div className='download-btn-group'>
                                        <div className="assigned-drop" ref={projectRef}>
                                            <Dropdown align="start" className={"short-btn"} show={showProject}>
                                                <Dropdown.Toggle variant="short" className={`outline-drop ${selectedProjectName?.length > 0 ? 'active' : ' '}`} id="dropdown-autoclose-true" onClick={() => { setShowProject(!showProject) }}>
                                                    {selectedProjectName?.length === 0 && 'All Projects'}
                                                    {selectedProjectName?.length > 1 ?
                                                        <>
                                                            <span>{selectedProjectName[0]?.label}</span> +
                                                            <span>{selectedProjectName?.length - 1 || ''} </span>
                                                        </>
                                                        : <span>{selectedProjectName[0]?.label || ''}</span>}
                                                    <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear border-b-2 !pb-2">
                                                        <a className='fz12 black-dark-700 fw-po-medium pr-hover'>All Projects</a>
                                                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0 text-primary uppercase' onClick={handleClearProjectSelection}>Clear all</a>
                                                    </div> */}
                                                    <div className='drop-search'>
                                                        <div className={`form-group position-relative search-input ${searchProject ? 'input-active' : ''} `}>
                                                            <div className="serch-icon">
                                                                <img src={SearchIcon} className="" />
                                                            </div>
                                                            <input
                                                                type="text"
                                                                placeholder="Search"
                                                                className={`searchinput`}
                                                                value={searchProject}
                                                                onChange={(e) => handleProjectSearch(e)}
                                                            />
                                                            {searchProject &&
                                                                <div className="close-icon" onClick={() => {
                                                                    setSearchProject('')
                                                                    setProjects(allProject)
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                                                    </svg>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <div className='input-list'>
                                                        {selectedProjectList?.length > 0 && selectedProjectList?.map((data, index) => {
                                                            return (
                                                                <div className='dropdown-item' key={`employee_${data.label}_${data.value}`}>
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            id={`employee_${data.label}_${data.value}`}
                                                                            type="checkbox"
                                                                            name={data.label}
                                                                            onChange={() => handleChangeProject(data)}
                                                                            checked={selectedProject?.includes(data.value)}
                                                                        />
                                                                        <label className="form-check-label cursor-pointer" htmlFor={`employee_${data.label}_${data.value}`}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {projects?.length > 0 ? projects.map((data, index) => {
                                                            return (
                                                                <div className='dropdown-item' key={`project_${data.label}_${data.value}`}>
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            id={`project_${data.label}_${data.value}`}
                                                                            type="checkbox" name={data.label}
                                                                            onChange={() => handleChangeProject(data)}
                                                                            checked={selectedProject?.includes(data.value)}
                                                                        />
                                                                        <label className="form-check-label cursor-pointer" htmlFor={`project_${data.label}_${data.value}`}>
                                                                            {data.label}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : selectedProjectList?.length === 0 && <p>No Records Found</p>}
                                                    </div>
                                                    {/* <div className="aply-btn">
                                                        <button className='pr-btn d-block' onClick={() => {
                                                            // getAllRecords();
                                                            handleCloseProject();
                                                            setShowProject(false);
                                                        }}>Apply filter</button>
                                                    </div> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="justify-content-md-end d-flex align-items-center gap-12">
                            <div className={`flex items-center inventory-grid`}>
                                <button
                                    className={` flex items-center !px-2 !py-1 !border rounded-l-lg ${!listView ? 'bg-[#2064FA]/10 !border-[#2064FA]/10 text-[#2064FA] ' : 'normal'}`}
                                    onClick={() => {
                                        setGridProjectSelected(selectedProject[0])
                                        setListView(false)
                                        getGridProjectTab()
                                    }}
                                >
                                    <ViewgridIcon className={`${!listView ? ' !fill-[#2064FA] ' : 'fill-[#DEDEDE]'}`} />
                                    Grid View
                                </button>
                                <button className={`flex items-center !border rounded-r-lg !px-2 !py-1 ${listView ? 'bg-[#2064FA]/10 !border-[#2064FA]/10 text-[#2064FA]' : 'normal'}`}
                                    onClick={() => {
                                        getProjectUnitLists()
                                        setListView(true)
                                    }}>
                                    <ViewlistIcon className={`${listView ? ' !fill-[#2064FA] ' : 'fill-[#92929D]'}`} />
                                    List View
                                </button>
                            </div>
                            <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search for Units'} classname='mr-0' />
                            {/* <div>
                                <button
                                    className={`inter fz14 fw-po-medium black-dark-700 inline-flex items-center`}
                                    onClick={() => setShowfilter(true)}
                                >                                    
                            <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                            Filter
                            ({Object.keys(filters).length})
                        </button>
                    </div> */}

                        </div>
                    </div>
                </div>
                {
                    !listView && <Tabs
                        tabList={tabList}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabName={selectedTab}
                        tabRowCount={tabRowCount}
                    />
                }
            </div >
            {listView && <div className='grid grid-cols-6 gap-6 px-[20px]'>
                <div
                    className={`${!selectedStatus ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                    onClick={() => setSelectedStatus(null)}
                >
                    <h4 className='m-0'>{inventoryStatsCount.total || '-'}</h4>
                    <p className='m-0 text-primary'>All</p>
                </div>
                {
                    inventoryStats.length > 0 && inventoryStats.map((stat, index) => (
                        <div
                            className={`${selectedStatus === stat.display_name ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                            onClick={() => setSelectedStatus(stat.display_name)}
                            key={`${stat.uuid}_${index}`}
                        >
                            <h4 className='m-0'>{inventoryStatsCount[stat.display_name] || '-'}</h4>
                            <p className='m-0 text-primary'>{StatusName[stat.display_name] ? StatusName[stat.display_name] : stat.display_name}</p>
                        </div>
                    ))
                }
                {/* <div
                    className={`${selectedStatus === 'Pending_confirmation' ? 'border-primary bg-blue-light' : ' border-grayLight'} border rounded-md p-2 cursor-pointer`}
                    onClick={() => setSelectedStatus('Pending_confirmation')}
                >
                    <h4 className='m-0'>{inventoryStatsCount['Pending_confirmation'] || '-'}</h4>
                    <p className='m-0 text-primary'>Pending Confirmation</p>
                </div> */}
            </div>}
            {
                listView && <div className='!pl-5 h-full flex flex-col !mt-5 overflow-auto'>
                    <TableView
                        unitList={unitList}
                        basePriceData={basePriceData}
                        setSelectedUnit={setSelectedUnit}
                        setShowUnitDetailModal={setShowUnitDetailModal}
                        setShowEditUnitModal={setShowEditUnitModal}
                        searchText={searchText}
                        getSelectedUnitBookings={getSelectedUnitBookings}
                        handleMarkAvailableAndUnavailable={handleMarkAvailableAndUnavailable}
                        basePriceConfigMap={basePriceConfigMap}
                        allowedPermission={allowedPermissions || []}
                        getEOILeadDetails={getEOILeadDetails}
                        isUnitTypePlot={isUnitTypePlot}
                    />
                </div>
            }

            {
                !listView && <GridView
                    unitList={unitList}
                    isUnitTypePlot={isUnitTypePlot}
                    getSelectedUnitBookings={getSelectedUnitBookings}
                    inventoryStats={inventoryStats}
                    inventoryStatsCount={gridInventoryStats}
                    setSelectedStatus={setSelectedStatus}
                    selectedStatus={selectedStatus}
                    unitNameWiseCount={unitNameWiseCount}
                    gridProjectUnits={gridProjectUnits}
                    searchText={searchText}
                    selectedTab={selectedTab}
                    userProfile={userProfile}
                />
            }
            {
                listView && <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!relative !py-3 !basis-0 !w-full'
                />
            }

            {/* Unit details */}
            {
                showUnitDetailModal && <BookindDetailsModal
                    show={showUnitDetailModal}
                    selectedBooking={selectedUnit}
                    closeModal={() => setShowUnitDetailModal(false)}
                    handleViewMore={() => {
                        setShowUnitDetailModal(false);
                        setShowDetailsModal(true);
                    }}
                    editModal={() => {
                        setShowUnitDetailModal(false);
                        setShowEditUnitModal(true);
                    }}
                    getBlockWiseUnits={getBlockWiseUnits}
                    selectedTab={selectedTab}
                    getProjectUnitLists={getProjectUnitLists}
                    allowedPermission={allowedPermissions || []}
                    operation={allowedPermissions.includes('verify_bookings_eoi') ? '' : 'view only'}
                />
            }

            {
                showDetailsModal && <DetailsModal
                    show={showDetailsModal}
                    selectedBooking={selectedUnit}
                    closeModal={() => setShowDetailsModal(false)}
                    handleBack={() => {
                        setShowUnitDetailModal(true);
                        setShowDetailsModal(false);
                    }}
                    allowedPermission={allowedPermissions || []}
                />
            }
            {/* Unit details for non booked unit */}
            {
                showUnitDetails && <UnitDetails
                    selectedUnit={selectedUnit}
                    show={showUnitDetails}
                    selectedProject={selectedProject}
                    closeModal={() => setShowUnitDetails(false)}
                    setShowBookingModal={setShowBookingModal}
                    setShowTokenModal={setShowTokenModal}
                    setLeadType={setLeadType}
                />
            }
            {
                showEditUnitModal && <EditUnitDetails
                    selectedUnit={selectedUnit}
                    show={showEditUnitModal}
                    selectedProject={selectedProject}
                    inventoryStats={inventoryStats}
                    getProjectUnitLists={getProjectUnitLists}
                    closeModal={() => setShowEditUnitModal(false)}
                    allowedPermission={allowedPermissions || []}
                    allProject={allProject}
                />
            }
            {
                showTokenModal &&
                <EOIModal
                    show={showTokenModal}
                    closeModal={() => setShowTokenModal(false)}
                    leadType={leadType}
                />
            }
            {
                showBookingModal &&
                <BookingModal
                    show={showBookingModal}
                    closeModal={() => setShowBookingModal(false)}
                    leadType={leadType}
                />
            }
        </div >
    )
}

export default Inventory;