import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { leadTypesEnum, leadTypesNameEnum } from '../../../config/enums';
import { getDashboardCounts } from '../../../services/private/company.service';
import Consumer from '../../../helpers/context';


import { ReactComponent as LeftIcon } from '../../../assets/icons/owlleft.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/owlright.svg';
import { toINRFormat } from '../../../helpers/helpers';
import useDocumentTitle from '../../../custom hooks/useDocumentTitile';
import Skeleton from 'react-loading-skeleton';
import TooltipText from '../../../components/Tooltip';

export default function LeadsTopBarTab({
    handleLeadTypeChange,
    count,
    leadType,
    allowedPermissions,
    loadingCount,
    handleSiteVisitEventFilter,
    sitevisitEventFilter,
    setSelectedBookingTab
}) {
    const ROLE_NAME = localStorage.getItem('role');

    useDocumentTitle(leadTypesNameEnum[leadType])

    const numberFormat = (num) => {
        if (num >= 100000) {
            return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num
    }

    const { userProfile } = useContext(Consumer);

    const [showButtons, setShowButtons] = useState(false);
    const [sitevisitShow, setSiteVisitShow] = useState(false);
    const [siteVisitCompleteShow, setSiteVisitCompleteShow] = useState(false)
    const allTabsRef = useRef(null);
    const sitevisittarget = useRef(null);
    const sitevisitCompletetarget = useRef(null);
    const [tabScrollPosition, setTabScrollPosition] = useState(0)


    useEffect(() => {
        const handleResize = () => {
            if (allTabsRef.current) {
                setShowButtons(allTabsRef.current.clientWidth <= 1300);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollRight = () => {
        document.getElementById('leads-pills-tab').scrollLeft -= 80;
        setTabScrollPosition(parseInt(document.getElementById('leads-pills-tab').scrollLeft))
    }
    const scrollLeft = () => {
        document.getElementById('leads-pills-tab').scrollLeft += 80;
        setTabScrollPosition(parseInt(document.getElementById('leads-pills-tab').scrollLeft))
    }

    return (
        <div className='flex items-center gap-x-3 md:!pr-4 !pr-2 w-full !border-b-2 !border-grayLight'>
            {tabScrollPosition > 0 && <button className='scrbtn' onClick={scrollRight}><LeftIcon /></button>}
            <div className="all-tabs max-w-full overflow-x-auto overflow-y-hidden w-full" ref={allTabsRef} id='leads-pills-tab'>
                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                    <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.ALL_LEADS ? 'active' : ''}`}
                            id="all-leads-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="all-leads"
                            aria-selected="true"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.ALL_LEADS)}
                        >
                            <TooltipText title={toINRFormat(count.leadsCount)} placement='right'>
                                <span>
                                    {!loadingCount && <span>All ({numberFormat(count.leadsCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1'>All</span> <Skeleton count={1} height="20px" width="30px" /></span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.NEW_LEADS ? 'active' : ''}`}
                            id="new-leads-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="new-leads"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.NEW_LEADS)}
                        >
                            <TooltipText title={toINRFormat(count.newLeadsCount)} placement='right'>
                                <span>
                                    {/* <span>New ({numberFormat(count.newLeadsCount)})</span> */}
                                    {!loadingCount && <span>New ({numberFormat(count.newLeadsCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1'>New</span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.FOLLOWUPS ? 'active' : ''}`}
                            id="followups-tab"
                            data-toggle="tab"
                            href="#followups"
                            role="tab"
                            aria-controls="followups"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.FOLLOWUPS)}
                        >
                            <TooltipText title={toINRFormat(count.followupsCount)} placement='right'>
                                <span>
                                    {/* <span>Followups ({numberFormat(count.followupsCount)})</span> */}
                                    {!loadingCount > 0 && <span>Followups ({numberFormat(count.followupsCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'><span className='mr-1'> Followups</span> <Skeleton count={1} height="20px" width="30px" /></span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.SITE_VISITS_SCHEDULE ? 'active' : ''}`}
                            id="sitevisits-tab"
                            data-toggle="tab"
                            href="#sitevisits"
                            role="tab"
                            aria-controls="sitevisits"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.SITE_VISITS_SCHEDULE)}
                        >
                            <TooltipText title={toINRFormat(count.siteVisitScheduledCount)} placement='right'>
                                <span>
                                    {/* <span>Site Visits ({numberFormat(count.siteVisitsCount)})</span> */}
                                    {!loadingCount && <span>SV Scheduled ({numberFormat(count.siteVisitScheduledCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' > SV Scheduled </span> <Skeleton count={1} height="20px" width="30px" margin='0px' /></span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.SITE_VISITS_COMPLETE ? 'active' : ''}`}
                            id="sitevisitcomplete-tab"
                            data-toggle="tab"
                            href="#sitevisitcomplete"
                            role="tab"
                            aria-controls="sitevisits"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.SITE_VISITS_COMPLETE)}
                        >
                            <TooltipText title={toINRFormat(count.siteVisitCompletedCount)} placement='right'>
                                <span>
                                    {!loadingCount && <span>SV Completed ({numberFormat(count.siteVisitCompletedCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' > SV Completed </span> <Skeleton count={1} height="20px" width="30px" margin='0px' /></span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>
                    {(userProfile?.allowed_modules?.includes("final_negotiation") || userProfile?.allowed_modules?.includes("new_booking")) && <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.FINAL_NEGOTIATION ? 'active' : ''}`}
                            id="finalNegotiation-tab"
                            data-toggle="tab"
                            href="#finalNegotiation"
                            role="tab"
                            aria-controls="finalNegotiation"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.FINAL_NEGOTIATION)}
                        >
                            <TooltipText title={toINRFormat(count.finalNegotiationCount)} placement='right'>
                                <span>
                                    {!loadingCount && <span>Final Negotiation ({numberFormat(count.finalNegotiationCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' >Final Negotiation </span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>}
                    {!userProfile?.allowed_modules?.includes("new_booking") && <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.BOOKINGS ? 'active' : ''}`}
                            id="bookings-tab"
                            data-toggle="tab"
                            href="#bookings"
                            role="tab"
                            aria-controls="bookings"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.BOOKINGS)}
                        >
                            <TooltipText title={toINRFormat(count.bookingLeadsCount)} placement='right'>
                                <span>
                                    {/* <span>Bookings/EOIs ({numberFormat(count.bookingLeadsCount)})</span> */}
                                    {!loadingCount && <span>Bookings/EOIs ({numberFormat(count.bookingLeadsCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' > Bookings/EOIs </span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>}
                    {userProfile?.allowed_modules?.includes("new_booking") && <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.NEWEOI ? 'active' : ''}`}
                            id="eoi-tab"
                            data-toggle="tab"
                            href="#eoi"
                            role="tab"
                            aria-controls="bookings"
                            aria-selected="false"
                            onClick={() => {
                                setSelectedBookingTab({
                                    name: 'All',
                                    value: null
                                })
                                handleLeadTypeChange(leadTypesEnum.NEWEOI)
                            }}
                        >
                            <TooltipText title={toINRFormat(count.eoiOnlyCount)} placement='right'>
                                <span>
                                    {/* <span>
                                    {userProfile?.company_id === 581 ? 'Tokens' : 'EOIs'}
                                    ({numberFormat(count.eoiOnlyCount)})
                                </span> */}
                                    {!loadingCount && <span>{userProfile?.company_id === 581 ? 'Tokens' : 'EOIs'} ({numberFormat(count.eoiOnlyCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' > {userProfile?.company_id === 581 ? 'Tokens' : 'EOIs'} </span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>}
                    {userProfile?.allowed_modules?.includes("new_booking") && <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.NEWBOOKINGS ? 'active' : ''}`}
                            id="new-bookings-tab"
                            data-toggle="tab"
                            href="#newbookings"
                            role="tab"
                            aria-controls="bookings"
                            aria-selected="false"
                            onClick={() => {
                                setSelectedBookingTab({
                                    name: 'All',
                                    value: null
                                })
                                handleLeadTypeChange(leadTypesEnum.NEWBOOKINGS)
                            }}
                        >
                            <TooltipText title={toINRFormat(count.bookingsOnlyCount)} placement='right'>
                                <span>
                                    {/* <span>
                                    Bookings
                                    ({numberFormat(count.bookingsOnlyCount)})
                                </span> */}
                                    {!loadingCount && <span>Bookings ({numberFormat(count.bookingsOnlyCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' >Bookings</span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>}

                    {!(allowedPermissions?.includes('hide_dead_leads')) && <li className="nav-item">
                        <a
                            className={`nav-link ${leadType === leadTypesEnum.DEAD_LEAD ? 'active' : ''}`}
                            id="dead-leads-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="new-leads"
                            aria-selected="false"
                            onClick={() => handleLeadTypeChange(leadTypesEnum.DEAD_LEAD)}
                        >
                            <TooltipText title={toINRFormat(count.deadLeadsCount)} placement='right'>
                                <span>
                                    {/* <span>
                                    {userProfile?.company_id === 581 ? 'Dump' : 'Dead'}
                                    ({numberFormat(count.deadLeadsCount)})
                                </span> */}
                                    {!loadingCount && <span>{userProfile?.company_id === 581 ? 'Dump' : 'Dead'} ({numberFormat(count.deadLeadsCount)})</span>}
                                    {loadingCount && <span className='flex items-center m-0'> <span className='mr-1' >{userProfile?.company_id === 581 ? 'Dump' : 'Dead'} </span> <Skeleton count={1} height="20px" width="30px" /> </span>}
                                </span>
                            </TooltipText>
                        </a>
                    </li>}

                </ul>

            </div>
            {showButtons && <button className='scrbtn' onClick={scrollLeft} ><RightIcon /></button>}
        </div>
    )
}